import React, { useState } from 'react';
import { File } from '@creditas/icons';
import { Typography } from '@creditas/typography';
import { styled } from '@creditas/stylitas';
import { Theme } from '../../../../interfaces/theme';
import { downloadFile } from '../../../../services/file-engine/FileEngine';
import { Modal } from '../../../../modules';
import { AssignmentFile, FileType } from '../../../../types';

interface AssignmentDownloadFileButtonProps {
  files: AssignmentFile[];
  state: string;
}

export const FlexContainer = styled.h1`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  justify-content: space-between;
`;

export const FlexChild = styled.div<{ justifyContent: string }>`
  display: flex;
  justify-content: ${props => props.justifyContent};
  align-items: center;
  margin-right: 15px;
  margin-left: 15px;
`;

export const CustomButton = styled.button<
  { backgroundColor: string; background: string; disabled: boolean },
  Theme
>`
  background-color: ${props => props.backgroundColor};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: inline-block;
  line-height: 32px;
  min-width: 200px;
  overflow: hidden;
  padding: 8px 16px;
  position: relative;
  transition: color 0.3s ease;
  z-index: 1;
  color: white;
  font-family: Nunito Sans, Tahoma;
  font-size: 20px;
  font-weight: 700;
  &:hover {
    background: ${props => props.background};
  }
  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
    background-color: #cccccc;
    color: #778888;
    &:hover {
      background: #cccccc;
    }
  `}
`;

export const AssignmentDownloadFileButton: React.FC<AssignmentDownloadFileButtonProps> = ({
  files,
  state,
}) => {
  const [showFilesModal, setShowFilesModal] = useState(false);
  const getFileByType = (type: FileType): AssignmentFile | undefined => {
    return files.find(f => f.type === type);
  };
  const isNotExistFileByType = (type: FileType) => {
    return getFileByType(type) === undefined;
  };
  return (
    <>
      {files.length &&
      (state === 'WAITING_CONFIRMATION' || state === 'CONFIRMED' || state === 'ERROR') ? (
        <File.DownloadCloud
          onClick={() => setShowFilesModal(true)}
          fontSize="large"
          color="cornflowerblue"
          style={{ cursor: 'pointer' }}
          data-testid="download-enabled"
        />
      ) : (
        <File.DownloadCloud data-testid="download-disabled" fontSize="large" />
      )}
      <Modal canShow={showFilesModal} hide={() => setShowFilesModal(false)}>
        <FlexContainer>
          <FlexChild justifyContent="center">
            <Typography variant="h4">Lista de arquivos</Typography>
          </FlexChild>
          <br />
          <FlexChild justifyContent="space-between">
            <CustomButton
              disabled={isNotExistFileByType(FileType.ASSIGNMENT_INCLUSION_OPERATION)}
              onClick={() =>
                downloadFile(getFileByType(FileType.ASSIGNMENT_INCLUSION_OPERATION)?.link)
              }
              backgroundColor="#3377BB"
              background="#005599"
            >
              Remessa
            </CustomButton>
            <CustomButton
              disabled={isNotExistFileByType(FileType.ASSIGNMENT_REMOVAL_OPERATION)}
              onClick={() =>
                downloadFile(getFileByType(FileType.ASSIGNMENT_REMOVAL_OPERATION)?.link)
              }
              backgroundColor="#3377BB"
              background="#005599"
            >
              Baixa
            </CustomButton>
            <CustomButton
              disabled={isNotExistFileByType(FileType.CREDITOR_EXCHANGE)}
              onClick={() => downloadFile(getFileByType(FileType.CREDITOR_EXCHANGE)?.link)}
              backgroundColor="#3377BB"
              background="#005599"
            >
              Troca de Credor
            </CustomButton>
          </FlexChild>
          <br />
          <FlexChild justifyContent="space-around">
            <CustomButton
              disabled={isNotExistFileByType(FileType.ERROR)}
              background="#eb2c2c"
              backgroundColor="#EB4C4CFF"
              onClick={() => downloadFile(getFileByType(FileType.ERROR)?.link)}
            >
              Erros
            </CustomButton>
            <CustomButton
              disabled={isNotExistFileByType(FileType.WARNING)}
              background="#E89A38FF"
              backgroundColor="#F2BD78FF"
              onClick={() => downloadFile(getFileByType(FileType.WARNING)?.link)}
            >
              Alertas
            </CustomButton>
          </FlexChild>
          <br />
        </FlexContainer>
      </Modal>
    </>
  );
};
