import React from 'react';
import { Tag } from '@creditas/tag';

interface AssignmentStateProps {
  value: string;
  isPartialAssignment?: boolean;
}

export const AssignmentState: React.FC<AssignmentStateProps> = ({
  value,
  isPartialAssignment = false,
}) => {
  return (
    <>
      {value === 'ERROR' && <Tag label="Erro" variant="danger" />}
      {value === 'SUBMISSION_ERROR' && <Tag label="Erro de envio" variant="danger" />}
      {value === 'CANCELED' && <Tag label="Cancelada" variant="danger" />}
      {value === 'REQUESTED' && <Tag label="Solicitado" variant="info" />}
      {value === 'PROCESSING' && <Tag label="Processando" variant="info" />}
      {value === 'WAITING_CONFIRMATION' && <Tag label="Aguardando confirmação" variant="warning" />}
      {value === 'CONFIRMED' && !isPartialAssignment && (
        <Tag label="Confirmada" variant="success" />
      )}
      {value === 'CONFIRMED' && isPartialAssignment && (
        <Tag label="Confirmada parcialmente" variant="success" />
      )}
    </>
  );
};
