/* eslint-disable no-alert */
import React, { useState, useEffect } from 'react';

import { styled } from '@creditas/stylitas';
import { Button } from '@creditas/button';
import { InputField } from '@creditas/form-elements';
import { Action, Editor } from '@creditas/icons';
import { Typography } from '@creditas/typography';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell as TableCellOriginal,
} from '@creditas/table';

import { useHistory } from 'react-router-dom';
import FundService, { FundValuesResponse } from '../../services/Fund';
import { FormContainer } from '../FormCnabGenerate/FormCnabGenerate.style';
import { Title } from '../../modules/FormAttributes/style';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TableCell = styled(TableCellOriginal)`
  vertical-align: middle;
`;

const NewButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100px;
  margin-left: auto;
`;

const Link = styled.a`
  text-decoration: none;
`;

const SupportText = styled(Typography)`
  margin-top: 20px;
`;

const fundAPI = new FundService(localStorage.getItem('greencare-access-token'));

export const FundRegistrationList = () => {
  const [data, setData] = useState<Array<FundValuesResponse>>([]);
  const [fundsList, setFundsList] = useState<Array<FundValuesResponse>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const routerHistory = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fundAPI.list();
        const responseData = response.data;
        setData(responseData);
        setFundsList(responseData);
      } catch (err) {
        setError(`error ao carregar listagem de credores ${err?.message}`);
      }
    };

    fetchData();
    setLoading(false);
  }, []);

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const onFilter = ({ target }) => {
    const filter = target.value;
    setFundsList(
      data.filter(fund => {
        return fund.simplifiedName?.toLowerCase()?.indexOf(filter) > -1;
      }),
    );
  };

  const items = fundsList.map(fund => {
    return (
      <TableRow key={fund.platformId}>
        <TableCell>{fund.simplifiedName}</TableCell>
        <TableCell>{fund.createdAt}</TableCell>
        <TableCell>{fund.active ? 'Sim' : 'Não'}</TableCell>
        <TableCell>
          <a
            href={`${window.location.pathname}/edicao/${fund.platformId}`}
            onClick={e => {
              e.preventDefault();
              routerHistory.push(
                `${window.location.pathname}/edicao/${fund.platformId}/registration`,
              );
            }}
          >
            <Editor.Edit color="primary02" />
          </a>
        </TableCell>
      </TableRow>
    );
  });

  return (
    <Container>
      <FormContainer>
        <Title>
          <Typography variant="title">Credores Registrados</Typography>
        </Title>
        <Container>
          <Header>
            <InputField onChange={onFilter} label="Filtrar" type="text" />
            <Link href={`${window.location.pathname}/novo`}>
              <NewButton>
                Registrar Credor <Action.PlusCircle />
              </NewButton>
            </Link>
          </Header>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Credor</TableCell>
                <TableCell>Originacao</TableCell>
                <TableCell>Ativo?</TableCell>
                <TableCell>Acao</TableCell>
              </TableRow>
            </TableHead>
            {!loading && !error && <TableBody variant="striped">{items}</TableBody>}
          </Table>
          {loading && <SupportText variant="textSupport">Carregando...</SupportText>}
          {error && (
            <SupportText variant="textSupport">Erro ao carregar dados - {error}</SupportText>
          )}
        </Container>
      </FormContainer>
    </Container>
  );
};
