import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { envVars } from '../envVars';
import { responseErrorInterceptor } from './Interceptors';
import { Account } from './Account';

export type FundValues = {
  name: string;
  nickname: string;
  category: string;
  simplifiedName: string;
  cnpj: string;
  issueNumber: string;
  active: boolean;
  interestRateBase: string | number;
  seniorSharesExpirationDate: string;
  manager: string;
  custodian: string;
  administrator: string;
  activatedAt: string;
  products: Array<'AUTO' | 'HOME' | 'PENSION' | 'PAYROLL'>;
  accounts?: Account[];
  hasBillingService: boolean;
};

export type FundValuesResponse = FundValues & {
  platformId: string;
  version: any;
  createdAt: string;
};

export const isUnderwriter = (fund: FundValuesResponse): boolean => {
  return fund.category === 'UNDERWRITER';
};

export default class FundService {
  private authToken: string | null;

  private httpClient: AxiosInstance;

  constructor(authToken: string | null) {
    this.authToken = authToken;
    this.httpClient = axios.create();
    this.httpClient.interceptors.response.use(response => response, responseErrorInterceptor);
  }

  buildConfig = (accept = 'application/vnd.creditas.v2+json'): AxiosRequestConfig => {
    return {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.authToken}`,
        Accept: accept,
      },
    };
  };

  list = (): Promise<AxiosResponse<Array<FundValuesResponse>>> => {
    const fundUrl = `${envVars.FUND_URL}/funds`;

    return this.httpClient.get(fundUrl, this.buildConfig());
  };

  getById = (id: string): Promise<AxiosResponse<FundValuesResponse>> => {
    const fundUrl = `${envVars.FUND_URL}/funds/${id}`;

    return this.httpClient.get(fundUrl, this.buildConfig('application/vnd.creditas.v3+json'));
  };

  create = (fundValues: FundValues): Promise<AxiosResponse<FundValuesResponse>> => {
    const fundUrl = `${envVars.FUND_URL}/funds`;

    return this.httpClient.post(fundUrl, fundValues, this.buildConfig());
  };

  update = (id: string, fundValues: FundValues): Promise<AxiosResponse<FundValuesResponse>> => {
    const fundUrl = `${envVars.FUND_URL}/funds/${id}`;

    return this.httpClient.put(fundUrl, fundValues, this.buildConfig());
  };
}
