import { styled } from '@creditas/stylitas';

export const FlexContainer = styled.h1`
  display: flex;
  margin-top: 20px;
`;

export const FlexChild = styled.div`
  margin-right: 10px;
`;

export const divStyle = {
  display: 'none',
};
