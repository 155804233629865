import React, { useState } from 'react';

import { Grid } from '@creditas/layout';
import { Button } from '@creditas/button';
import { Typography } from '@creditas/typography';
import { Toggle } from '@creditas/toggle';
import { FeedbackMessage } from '@creditas/feedback-message';
import { FieldArray, Form, Formik } from 'formik';
import { styled } from '@creditas/stylitas';
import { SelectField } from '../../../modules/FormAttributes/SelectField';
import { Validation } from './validation';
import { TextInput } from '../../../modules/FormAttributes/InputField';
import { CheckboxField } from '../../../modules/FormAttributes/Checkbox';
import { MarginTop } from '../../../modules/FormAttributes/style';
import {
  CATEGORIES,
  FormAttributes,
  PRODUCTS,
  startFormFundRegistration,
  translateCategory,
} from './form-attributes';
import { FundValuesResponse } from '../../../services/Fund';
import { RadioInputStyled } from '../../../modules/FormAttributes/RadioInput';

const ToggleWrapper = styled.div`
  margin-bottom: 40px;
`;

const Label = styled(Typography)`
  margin-bottom: 10px;
`;

const StyledFeedbackMessage = styled(FeedbackMessage)`
  margin-top: 36px;
`;

const CheckboxWrapper = styled('div')`
  white-space: nowrap;
  max-width: 30%;
`;

type FormFundRegistrationProps = {
  sendData: (values: FormAttributes) => Promise<void>;
  formValues?: FundValuesResponse;
  messageError?: string;
};

const custodianOptions = [
  {
    value: 'VÓRTX DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
    text: 'VÓRTX DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
  },
  {
    value: 'BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.',
    text: 'BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.',
  },
  {
    value: 'Certificadora de créditos imobiliários e participações SA',
    text: 'Certificadora de créditos imobiliários e participações SA',
  },
  { value: 'Oliveira Trust DTVM S.A.', text: 'Oliveira Trust DTVM S.A.' },
  {
    value: 'Banvox Distribuidora de Títulos e Valores Mobiliários Ltda.',
    text: 'Banvox Distribuidora de Títulos e Valores Mobiliários Ltda.',
  },
  {
    value: 'BANCO ANDBANK (BRASIL) S.A.',
    text: 'BANCO ANDBANK (BRASIL) S.A.',
  },
  {
    value: 'LIMINE TRUST DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA',
    text: 'LIMINE TRUST DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA',
  },
  {
    value: 'SIG CAPITAL SOLUCOES FINANCEIRAS',
    text: 'SIG CAPITAL SOLUCOES FINANCEIRAS',
  },
  {
    value: 'COMPANHIA HIPOTECARIA PIRATINI',
    text: 'COMPANHIA HIPOTECARIA PIRATINI',
  },
  {
    value: 'RECOVERY',
    text: 'RECOVERY',
  },
];

const administratorOptions = [
  {
    value: 'BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.',
    text: 'BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.',
  },
  {
    value: 'Oliveira Trust DTVM S.A.',
    text: 'Oliveira Trust DTVM S.A.',
  },
  {
    value: 'Banvox Distribuidora de Títulos e Valores Mobiliários Ltda.',
    text: 'Banvox Distribuidora de Títulos e Valores Mobiliários Ltda.',
  },
  {
    value: 'Certificadora de créditos imobiliários e participações SA',
    text: 'Certificadora de créditos imobiliários e participações SA',
  },
  {
    value: 'VERT COMPANHIA SECURITIZADORA',
    text: 'VERT COMPANHIA SECURITIZADORA',
  },
  {
    value: 'BANCO ANDBANK (BRASIL) S.A.',
    text: 'BANCO ANDBANK (BRASIL) S.A.',
  },
  {
    value: 'LIMINE TRUST DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA',
    text: 'LIMINE TRUST DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA',
  },
  {
    value: 'SIG CAPITAL SOLUCOES FINANCEIRAS',
    text: 'SIG CAPITAL SOLUCOES FINANCEIRAS',
  },
  {
    value: 'COMPANHIA HIPOTECARIA PIRATINI',
    text: 'COMPANHIA HIPOTECARIA PIRATINI',
  },
  {
    value: 'RECOVERY',
    text: 'RECOVERY',
  },
];

const managerOptions = [
  {
    value: 'Empírica Investimentos Gestão de Recursos Ltda.',
    text: 'Empírica Investimentos Gestão de Recursos Ltda.',
  },
  {
    value: 'VERT COMPANHIA SECURITIZADORA',
    text: 'VERT COMPANHIA SECURITIZADORA',
  },
  {
    value: 'XP Vista Asset Management LTDA.',
    text: 'XP Vista Asset Management LTDA.',
  },
  {
    value: 'ANGÁ ADMINISTRAÇÃO DE RECURSOS LTDA.',
    text: 'ANGÁ ADMINISTRAÇÃO DE RECURSOS LTDA.',
  },
  {
    value: 'VERT Gestora de Recursos Financeiros Ltda',
    text: 'VERT Gestora de Recursos Financeiros Ltda',
  },
  {
    value: 'BANCO ANDBANK (BRASIL) S.A.',
    text: 'BANCO ANDBANK (BRASIL) S.A.',
  },
  {
    value: 'KANASTRA ADMINISTRACAO DE RECURSOS LTDA',
    text: 'KANASTRA ADMINISTRACAO DE RECURSOS LTDA',
  },
  {
    value: 'SIG CAPITAL SOLUCOES FINANCEIRAS',
    text: 'SIG CAPITAL SOLUCOES FINANCEIRAS',
  },
  {
    value: 'COMPANHIA HIPOTECARIA PIRATINI',
    text: 'COMPANHIA HIPOTECARIA PIRATINI',
  },
  {
    value: 'RECOVERY',
    text: 'RECOVERY',
  },
];

export const FormFundRegistration = ({
  sendData,
  formValues,
  messageError,
}: FormFundRegistrationProps) => {
  const [enableShowCategoryError, setEnableShowCategoryError] = useState<boolean>(false);

  return (
    <Formik
      initialValues={startFormFundRegistration(formValues)}
      validationSchema={Validation}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        await sendData(values);
        setSubmitting(false);
      }}
    >
      {({ values, errors, touched, isSubmitting, handleChange, setFieldValue }) => (
        <Form>
          <Grid container>
            <Grid item>
              <ToggleWrapper>
                <Label variant="paragraph">Ativo?</Label>
                <Toggle
                  enabled={values.active}
                  onClick={() => setFieldValue('active', !values.active)}
                />
              </ToggleWrapper>
            </Grid>

            <TextInput
              name="name"
              dataTestId="name"
              label="Nome do credor"
              colStart={1}
              colEnd={6}
              error={errors.name}
              touched={touched.name}
              onChange={handleChange}
              fieldValue={values.name}
              pattern="[A-Za-z]{3}"
            />
            <TextInput
              name="nickname"
              dataTestId="nickname"
              label="Apelido"
              colEnd={6}
              error={errors.nickname}
              touched={touched.nickname}
              onChange={handleChange}
              fieldValue={values.nickname}
            />
            <TextInput
              name="simplifiedName"
              dataTestId="simplifiedName"
              label="Nome simplificado"
              colEnd={6}
              error={errors.simplifiedName}
              touched={touched.simplifiedName}
              onChange={handleChange}
              fieldValue={values.simplifiedName}
            />
            <TextInput
              name="issueNumber"
              dataTestId="issueNumber"
              label="Numeração de Emissão"
              colEnd={6}
              touched={touched.issueNumber}
              onChange={handleChange}
              fieldValue={values.issueNumber}
            />
            <TextInput
              name="cnpj"
              dataTestId="cnpj"
              label="Cnpj"
              colEnd={6}
              error={errors.cnpj}
              touched={touched.cnpj}
              onChange={handleChange}
              fieldValue={values.cnpj}
            />
            <SelectField
              name="manager"
              dataTestId="manager"
              onChange={handleChange}
              error={errors.manager}
              touched={touched.manager}
              label="Manager"
              options={managerOptions}
              value={values.manager}
            />
            <SelectField
              name="custodian"
              dataTestId="custodian"
              value={values.custodian}
              onChange={handleChange}
              error={errors.custodian}
              touched={touched.custodian}
              label="Custodiante"
              options={custodianOptions}
            />
            <SelectField
              dataTestId="administrator"
              name="administrator"
              onChange={handleChange}
              label="Administrator"
              error={errors.administrator}
              touched={touched.administrator}
              options={administratorOptions}
              value={values.administrator}
            />
            <TextInput
              dataTestId="interestRateBase"
              name="interestRateBase"
              label="Base de cálculo"
              error={errors.interestRateBase}
              touched={touched.interestRateBase}
              onChange={handleChange}
              fieldValue={values.interestRateBase}
            />
            <TextInput
              dataTestId="activatedAt"
              name="activatedAt"
              type="date"
              label="Data de Origem"
              error={errors.activatedAt}
              touched={touched.activatedAt}
              onChange={handleChange}
              fieldValue={values.activatedAt}
            />
            <TextInput
              dataTestId="seniorSharesExpirationDate"
              name="seniorSharesExpirationDate"
              type="date"
              label="Data Cota Sênior"
              error={errors.seniorSharesExpirationDate}
              touched={touched.seniorSharesExpirationDate}
              onChange={handleChange}
              fieldValue={values.seniorSharesExpirationDate}
            />
          </Grid>
          <CheckboxWrapper>
            <CheckboxField
              css={{ whiteSpace: 'nowrap', textTransform: 'uppercase' }}
              disabled={false}
              onChange={() => setFieldValue('hasBillingService', !values.hasBillingService)}
              id="hasBillingService"
              value="Somos agente de cobrança"
              checked={values.hasBillingService}
            />
          </CheckboxWrapper>
          {values?.products && values?.products?.length > 0 && (
            <>
              <Label variant="paragraph">Produtos</Label>
              <FieldArray
                name="products"
                render={() => (
                  <Grid container>
                    {PRODUCTS.map(product => (
                      <CheckboxField
                        disabled
                        id={product}
                        key={product}
                        value={product}
                        dataTestId={product}
                        checked={values.products.includes(product)}
                        colEnd={product.length > 10 ? 3 : 2}
                      />
                    ))}
                  </Grid>
                )}
              />
            </>
          )}
          <Label variant="paragraph">Categoria</Label>
          <FieldArray
            name="category"
            render={() => (
              <Grid container>
                {CATEGORIES.map(category => (
                  <RadioInputStyled
                    data-testid={category}
                    value={category}
                    id={category}
                    key={category}
                    name={category}
                    onChange={() => {
                      setFieldValue('category', category);
                      setEnableShowCategoryError(true);
                    }}
                    checked={values.category === category}
                    label={translateCategory[category]}
                  />
                ))}
              </Grid>
            )}
          />
          {enableShowCategoryError && errors.category && (
            <Typography color="danger" variant="support">
              {errors.category}
            </Typography>
          )}
          {messageError && (
            <StyledFeedbackMessage variant="danger">{messageError}</StyledFeedbackMessage>
          )}
          <MarginTop>
            <Button
              type="submit"
              fullWidth
              isLoading={isSubmitting}
              disabled={isSubmitting}
              onClick={() => {
                setEnableShowCategoryError(true);
              }}
            >
              Salvar
            </Button>
          </MarginTop>
        </Form>
      )}
    </Formik>
  );
};
