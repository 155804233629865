/* eslint-disable @typescript-eslint/no-explicit-any */
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createRootReducer } from './rootReducer';

const composeEnhancers =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const preloadedState = {};

const configureStore = (): any =>
  createStore(createRootReducer(), preloadedState, composeEnhancers(applyMiddleware(thunk)));

export default configureStore;
