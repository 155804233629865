import React from 'react';
import { RadioInput, RadioInputProps } from '@creditas/input';
import { styled } from '@creditas/stylitas';

const Wrapper = styled.div`
  label:hover {
    border: 1px solid #3377bb;
  }

  label {
    border: 1px solid #aabbbb;
    background-color: transparent !important;
    margin: 0;
  }

  span {
    background-color: white;
    border: 1px solid #aabbbb;
  }
`;

export const RadioInputStyled = (props: RadioInputProps) => {
  return (
    <Wrapper>
      <RadioInput {...props} />
    </Wrapper>
  );
};
