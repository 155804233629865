/* eslint-disable no-alert */
import React, { useContext, useState } from 'react';

import { Container } from '@creditas/layout';
import { Typography } from '@creditas/typography';

import { generatePath, useHistory } from 'react-router-dom';
import FundService, { FundValuesResponse } from '../../services/Fund';
import { FormAttributes } from './components/form-attributes';
import { FormContainer } from '../FormCnabGenerate/FormCnabGenerate.style';
import { Title } from '../../modules/FormAttributes/style';
import { FormFundRegistration } from './components/FormFundRegistration';
import { Breadcrumb } from './components/Breadcrumb';
import { CustomPropsContext } from '../../contexts';

const fundAPI = new FundService(localStorage.getItem('greencare-access-token'));

export const FundRegistrationAdd: React.FC = () => {
  const [fundCreated, setFundCreated] = useState<FundValuesResponse>();
  const [error, setError] = useState<string>('');
  const routerHistory = useHistory();

  const { basePath } = useContext(CustomPropsContext);

  const sendData = async (values: FormAttributes) => {
    try {
      const submitValues = {
        ...values,
        interestRateBase: Number(values.interestRateBase),
      };
      const response = await fundAPI.create(submitValues);
      alert(`credor ${values.name} criado com sucesso. id: ${response.data.platformId}`);
      setFundCreated(response.data);
    } catch (err) {
      console.error(err);
      setError(`erro ao salvar dados ${err?.message}`);
    }
  };

  const navigation = [{ text: 'Credores', link: generatePath('/credores') }, { text: 'Registro' }];

  if (fundCreated) {
    routerHistory.push(`${basePath}/credores/edicao/${fundCreated?.platformId}/accounts`);
  }

  return (
    <Container maxWidth="lg">
      <Breadcrumb navigation={navigation} />
      <FormContainer>
        <Title>
          <Typography variant="title">Registro de Credor</Typography>
        </Title>
        <FormFundRegistration sendData={sendData} messageError={error} />
      </FormContainer>
    </Container>
  );
};
