const GREENCARE_ACCESS_TOKEN = 'greencare-access-token';

export const getAccessToken = (): string => {
  return localStorage.getItem(GREENCARE_ACCESS_TOKEN) || '';
};

export const logout = (): void => {
  localStorage.setItem(GREENCARE_ACCESS_TOKEN, '');
  window.location.replace('/login');
};
