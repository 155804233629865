import React, { useState } from 'react';
import { FeedbackMessage } from '@creditas/feedback-message';
import { FormAttributesAccount } from './form-attributes';
import { FormAccount } from './FormAccount';
import { FormContainer } from '../../../modules/FormAttributes/style';
import AccountService, { Account } from '../../../services/Account';
import useAccount from '../../../contexts/AccountContext';

type CreateAccountProps = {
  fundPlatformId: string;
  accountCopy?: Account[];
};

interface FeedbackMessage {
  show: boolean;
  message?: string;
  variant?: 'success' | 'danger';
}

const accountAPI = new AccountService(localStorage.getItem('greencare-access-token'));

export const CreateAccount = ({ fundPlatformId, accountCopy }: CreateAccountProps) => {
  const { accounts, setAccounts } = useAccount();
  const [feedbackMessage, setFeedbackMessage] = useState<FeedbackMessage>({
    show: false,
    message: '',
    variant: 'danger',
  });
  const sendData = async (values: FormAttributesAccount) => {
    try {
      const submitValues = {
        ...values,
      };
      const { data: accountCreated } = await accountAPI.create(fundPlatformId, submitValues);
      const newAccount = {
        platformId: accountCreated.platformId,
        ...values,
      };
      accounts.push(newAccount);
      setAccounts([...accounts]);
      setFeedbackMessage({
        show: true,
        message: 'Conta bancária cadastrada com sucesso',
        variant: 'success',
      });
    } catch (err) {
      console.error(err);
      setFeedbackMessage({
        show: true,
        message: 'Error ao cadastrar a conta bancária',
        variant: 'danger',
      });
    }
  };

  return (
    <FormContainer>
      {feedbackMessage.show ? (
        <>
          <FeedbackMessage variant={feedbackMessage.variant}>
            {feedbackMessage.message}
          </FeedbackMessage>
          <br />
        </>
      ) : (
        <></>
      )}
      <FormAccount sendData={sendData} formValues={accountCopy} />
    </FormContainer>
  );
};
