export const convertToDate = (date: string): Date => {
  return new Date(
    date
      .split('/')
      .reverse()
      .join('/'),
  );
};

export const getFutureBusinessDate = (days: number): Date => {
  let result = Date.now();
  let addedDays = 0;
  while (addedDays < days) {
    result += 1 * 86400000;
    const day = new Date(result).getDay();
    if (!(day === 0 || day === 6)) addedDays += 1;
  }
  return new Date(result);
};

export const isWeekend = (date: Date): boolean => {
  const day = date.getDay();
  return day === 0 || day === 6;
};
