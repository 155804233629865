import fileDownload from 'js-file-download';
import { api } from './Api';
import { AssignmentResponse } from '../../types';

interface AssignmentQueryFilter {
  assignmentCode?: string;
  assignee?: string;
  assignor?: string;
  state?: string;
  assignedAt?: Date;
  confirmedAt?: Date;
}

export const getAssignments = (
  filter?: AssignmentQueryFilter,
  page = 0,
  size?: number,
): Promise<AssignmentResponse> => {
  return api.get('/assignments', {
    params: {
      ...filter,
      size,
      page: page > 0 ? page - 1 : page,
    },
  });
};

export const setAssignmentState = async (assignmentId: string, state: string): Promise<void> => {
  await api.patch(`/assignments/${assignmentId}/state`, { state });
};

export const downloadFile = async (url: string | undefined): Promise<void> => {
  const fileName = url.split('/').pop();
  await api.get(url, { responseType: 'blob' }).then(res => {
    fileDownload(res, fileName);
  });
};
