import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { envVars } from '../envVars';
import { responseErrorInterceptor } from './Interceptors';

export type Account = {
  platformId?: string;
  product: string;
  type: AccountType.Type;
  bankCode: string;
  bankName: string;
  branchNumber: string;
  accountNumber: string;
  accountDigit: string;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AccountType {
  export type Type =
    | 'DISBURSEMENT'
    | 'ORDINARY_COLLECTION'
    | 'EXTRAORDINARY_COLLECTION'
    | 'INBOUND'
    | 'INBOUND'
    | 'ESCROW';
  export const options = [
    {
      value: 'DISBURSEMENT',
      text: 'Desembolso ',
    },
    {
      value: 'ORDINARY_COLLECTION',
      text: 'Cobrança Ordinária ',
    },
    {
      value: 'EXTRAORDINARY_COLLECTION',
      text: 'Cobrança Extraordinária ',
    },
    {
      value: 'INBOUND',
      text: 'Recebimento ',
    },
    {
      value: 'ESCROW',
      text: 'Escrow ',
    },
  ];

  export const description = (type: Type): string => {
    const option = options.find(it => it.value === type);
    if (option) return option.text;
    return '';
  };
}

export default class AccountService {
  private authToken: string | null;

  private httpClient: AxiosInstance;

  constructor(authToken: string | null) {
    this.authToken = authToken;
    this.httpClient = axios.create();
    this.httpClient.interceptors.response.use(response => response, responseErrorInterceptor);
  }

  buildConfig = (): AxiosRequestConfig => {
    return {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.authToken}`,
        Accept: 'application/vnd.creditas.v2+json',
      },
    };
  };

  list = (fundPlatformId: string): Promise<AxiosResponse<Array<Account>>> => {
    const accountUrl = `${envVars.FUND_URL}/funds/${fundPlatformId}/accounts`;

    return this.httpClient.get(accountUrl, this.buildConfig());
  };

  create = (fundPlatformId: string, accountValues: Account): Promise<AxiosResponse<Account>> => {
    const accountUrl = `${envVars.FUND_URL}/funds/${fundPlatformId}/accounts`;

    return this.httpClient.post(accountUrl, accountValues, this.buildConfig());
  };

  update = (
    accountPlatformId: string,
    fundPlatformId: string,
    accountValues: Account,
  ): Promise<AxiosResponse<Account>> => {
    const accountUrl = `${envVars.FUND_URL}/funds/${fundPlatformId}/accounts/${accountPlatformId}`;

    return this.httpClient.patch(accountUrl, accountValues, this.buildConfig());
  };
}
