import React, { ChangeEvent, useEffect, useState } from 'react';

import { TextField } from '@creditas/input';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { FeedbackMessage } from '@creditas/feedback-message';
import { styled } from '@creditas/stylitas';
import { Container } from '@creditas/layout';
import { Typography } from '@creditas/typography';
import moment from 'moment';
import validator from 'validator';
import { Spinner } from '@creditas/spinner';
import { Table } from './components';
import { getAssignments, setAssignmentState } from '../../services/file-engine/FileEngine';
import FundService, { FundValuesResponse } from '../../services/Fund';
import { AssignmentStatus } from '../../types/Assignment';
import { Modal } from '../../modules';

const FlexContainer = styled.h1`
  display: flex;
  flex-direction: row;
`;
const FlexChild = styled.div`
  flex: 5 2 auto;
  margin: 5px;
`;

const ContainerLoading = styled.div`
  margin-top: 10%;
`;

interface AssignmentFilter {
  assignmentCode?: string;
  assigneePlatformId?: string;
  assignorPlatformId?: string;
  state?: string;
  assignedAt?: Date;
}

interface DateInputError {
  assignedAt?: boolean;
  confirmedAt?: boolean;
}
const fundAPI = new FundService(localStorage.getItem('greencare-access-token'));

export const AssignmnentList: React.FC = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState<AssignmentFilter>({});
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState<DateInputError>();
  const [fundsList, setFundsList] = useState<Array<FundValuesResponse>>([]);
  const [loadingUpdated, setLoadingUpdated] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fundAPI.list();
      const responseData = response.data;
      setFundsList(
        responseData.sort((fund1, fund2) => fund1.nickname.localeCompare(fund2.nickname)),
      );
    };
    fetchData();
  }, []);

  const handleFilterOnChange = (e: ChangeEvent<HTMLSelectElement>): void => {
    const { id, value } = e.target;
    setFilter({ ...filter, [id]: value });
  };

  const handleDateFilterOnChange = (e: ChangeEvent<HTMLSelectElement>): void => {
    const { id, value: date } = e.target;
    if (date === '' || validator.isDate(date, { format: 'DD/MM/YYYY' })) {
      setErrorMessage('');
      setError({ ...error, [id]: false });
      if (date === '') setFilter({ ...filter, [id]: date });
      else {
        setFilter({ ...filter, [id]: moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD') });
      }
    } else {
      setErrorMessage('Digite uma data válida!');
      setError({ ...error, [id]: true });
    }
  };

  const { result: assignments, loading, error: apiError, execute } = useAsync(
    () => getAssignments(filter, page, pageSize),
    [filter, page, pageSize],
    { setLoading: state => ({ ...state, loading: true }) },
  );

  const updateAssignmentStateCallback = useAsyncCallback(
    async (...args: string[]) => {
      setLoadingUpdated(true);
      await setAssignmentState(args[0], args[1]);
    },
    {
      onSuccess: () => {
        setLoadingUpdated(false);
        execute();
      },
      onError: e => {
        console.error('Error updating assignment state', e);
        setLoadingUpdated(false);
      },
    },
  );

  const assignorOptions = fundsList.map(fund => (
    <option value={fund.platformId}>{fund.simplifiedName}</option>
  ));

  const assigneeOptions = fundsList.map(fund => (
    <option value={fund.platformId}>{fund.simplifiedName}</option>
  ));

  const assignmentStatusOptions = Object.values(AssignmentStatus);

  const statusMapping: { [key: string]: string } = {
    [AssignmentStatus.WAITING_CONFIRMATION]: 'WAITING_CONFIRMATION',
    [AssignmentStatus.PROCESSING]: 'PROCESSING',
    [AssignmentStatus.CONFIRMED]: 'CONFIRMED',
    [AssignmentStatus.ERROR]: 'ERROR',
    [AssignmentStatus.CANCELED]: 'CANCELED',
    [AssignmentStatus.REQUESTED]: 'REQUESTED',
    [AssignmentStatus.SUBMISSION_ERROR]: 'SUBMISSION_ERROR',
  };

  return (
    <Container maxWidth="xl">
      <Modal closeClick={false} canShow={loadingUpdated} hide={() => setLoadingUpdated(false)}>
        <ContainerLoading>
          <Spinner message="Atualizando cessão..." />
        </ContainerLoading>
      </Modal>

      <Typography color="black" ariant="h6">
        Filtros
      </Typography>
      <FlexContainer>
        <FlexChild>
          <TextField
            id="assignorPlatformId"
            placeholder="Selecione um cedente"
            label="Cedente"
            select
            onChange={handleFilterOnChange}
          >
            <option value="">Selecione um cedente</option>
            {assignorOptions}
          </TextField>
        </FlexChild>
        <FlexChild>
          <TextField
            id="assigneePlatformId"
            placeholder="Selecione um cessionário"
            label="Cessionário"
            select
            onChange={handleFilterOnChange}
          >
            <option value="">Selecione um cessionário</option>
            {assigneeOptions}
          </TextField>
        </FlexChild>
      </FlexContainer>
      <FlexContainer>
        <FlexChild>
          <TextField
            id="state"
            placeholder="Selecione um status da cessão"
            label="Status da cessão"
            select
            onChange={handleFilterOnChange}
          >
            <option value="">Selecione um status de cessão</option>
            {assignmentStatusOptions.map(status => (
              <option value={statusMapping[status]}>{status}</option>
            ))}
          </TextField>
        </FlexChild>
        <FlexChild>
          <TextField
            id="assignedAt"
            placeholder="dd/mm/aaaa"
            label="Data de cessão"
            onChange={handleDateFilterOnChange}
            error={error?.assignedAt}
            feedbackText={error?.assignedAt ? errorMessage : ''}
          />
        </FlexChild>
        <FlexChild>
          <TextField
            id="assignmentCode"
            placeholder="Digite um código da cessão"
            label="Código da cessão"
            onChange={handleFilterOnChange}
          />
        </FlexChild>
      </FlexContainer>
      {assignments && (
        <Table
          data={assignments}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          updateAssignmentState={updateAssignmentStateCallback.execute}
        />
      )}
      {!assignments && loading && (
        <FeedbackMessage data-testid="feedback-loading" variant="info">
          Carregando cessões
        </FeedbackMessage>
      )}
      {apiError && <FeedbackMessage variant="danger">Erro ao carregar as cessões</FeedbackMessage>}
    </Container>
  );
};
