import { Checkbox } from '@creditas/checkbox';
import { Grid } from '@creditas/layout';
import React from 'react';
import { styled } from '@creditas/stylitas';
import { Fieldset } from './style';

type CheckboxFieldInputProps = {
  id: string;
  value: string;
  onChange?: () => void;
  colEnd?: number;
  checked?: boolean;
  dataTestId?: string;
  disabled?: boolean;
};

const CheckboxStyled = styled(Checkbox)`
  label {
    border-color: #aabbbb;
  }
  label,
  label:hover {
    background-color: transparent;
  }
  label::before {
    background-color: white;
    border: 1px solid #aabbbb;
  }
`;

export const CheckboxField = ({
  id,
  value,
  onChange,
  colEnd,
  checked,
  dataTestId,
  disabled,
}: CheckboxFieldInputProps) => {
  return (
    <Grid item colEnd={colEnd || 2}>
      <Fieldset>
        <CheckboxStyled
          data-testid={dataTestId}
          id={id}
          boxed
          disabled={disabled}
          onChange={onChange}
          fullWidth
          name={value}
          checked={checked}
        >
          {value}
        </CheckboxStyled>
      </Fieldset>
    </Grid>
  );
};
