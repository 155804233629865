import { InstallmentModifiers } from '../../@types/installment-modifiers';

const installmentModifiers: InstallmentModifiers[] = [
  {
    type: 'GOODWILL',
    factors: ['0.10'],
  },
  {
    type: 'MANUAL',
    factors: null,
  },
  {
    type: 'NO_GOODWILL',
    factors: null,
  },
];

export const getModifyingFactorsByType = (type: string): string[] => {
  return installmentModifiers.find(modifier => modifier.type === type)?.factors || [''];
};

export const getModifyingTypes = (): string[] => {
  return installmentModifiers.map(modifier => modifier.type);
};
