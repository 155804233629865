import { FundValues } from '../../../services/Fund';
import { Account } from '../../../services/Account';

type Products = 'AUTO' | 'HOME' | 'PENSION' | 'PAYROLL';

export enum CategoriesType {
  WAREHOUSE = 'WAREHOUSE',
  FINAL = 'FINAL',
  CRI = 'CRI',
  UNDERWRITER = 'UNDERWRITER',
  UNKNOWN = 'UNKNOWN',
  NPL = 'NPL',
}

export const PRODUCTS: Array<Products> = ['AUTO', 'HOME', 'PENSION', 'PAYROLL'];
export const CATEGORIES: Array<CategoriesType> = [
  CategoriesType.WAREHOUSE,
  CategoriesType.FINAL,
  CategoriesType.CRI,
  CategoriesType.UNDERWRITER,
  CategoriesType.NPL,
];

export const translateCategory: {
  [key: string]: string;
} = {
  UNDERWRITER: 'ORIGINADOR',
  WAREHOUSE: 'INTERMEDIÁRIO',
  FINAL: 'FINAL',
  CRI: 'CRI',
  NPL: 'NPL',
};

export interface FormAttributes {
  id: null | string;
  category: CategoriesType;
  name: string;
  nickname: string;
  simplifiedName: string;
  cnpj: string;
  issueNumber: string;
  active: boolean;
  interestRateBase: any;
  seniorSharesExpirationDate: string;
  manager: string;
  custodian: string;
  administrator: string;
  activatedAt: string;
  products: Array<Products>;
  hasBillingService: boolean;
}

export const startFormFundRegistration = (values?: FundValues): FormAttributes => {
  if (values?.cnpj)
    // eslint-disable-next-line no-param-reassign
    values.cnpj = values.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  return {
    id: null,
    category: CategoriesType.UNKNOWN,
    name: '',
    nickname: '',
    simplifiedName: '',
    cnpj: '',
    issueNumber: '',
    active: true,
    manager: '',
    custodian: '',
    administrator: '',
    activatedAt: new Date().toISOString()?.split('T')?.[0],
    seniorSharesExpirationDate: new Date().toISOString()?.split('T')?.[0],
    products: [],
    interestRateBase: '',
    hasBillingService: true,
    ...values,
  };
};

export interface FormAttributesAccount {
  id: null | string;
  type: string;
  bankCode: string;
  bankName: string;
  branchNumber: string;
  accountNumber: string;
  accountDigit: string;
  product: string;
}

export const startFormAccount = (values?: Account): FormAttributesAccount => {
  return {
    id: null,
    type: '',
    bankCode: '',
    bankName: '',
    branchNumber: '',
    accountNumber: '',
    accountDigit: '',
    product: '',
    ...values,
  };
};
