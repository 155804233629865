import React, { useContext, Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import {
  FormCnabGenerate,
  AssignmnentList,
  FundRegistrationList,
  FundRegistrationEdit,
  FundRegistrationAdd,
} from './pages';

import { CustomPropsContext } from './contexts/CustomPropsContext';

export const Routes = () => {
  const { basePath } = useContext(CustomPropsContext);

  return (
    <BrowserRouter>
      <Suspense fallback={null}>
        <Switch>
          <Route path={`${basePath}/cnab/gerador-de-cnab`} exact component={FormCnabGenerate} />
          <Route path={`${basePath}/cnab/cessoes`} exact component={AssignmnentList} />
          <Route path={`${basePath}/credores`} exact component={FundRegistrationList} />
          <Route path={`${basePath}/credores/novo`} exact component={FundRegistrationAdd} />
          <Route
            path={`${basePath}/credores/edicao/:id/:step`}
            exact
            component={FundRegistrationEdit}
          />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};
