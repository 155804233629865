import React, { Component } from 'react';
import { StoreContext } from 'redux-react-hook';
import { ThemeProvider } from '@creditas/stylitas';
import tokens from '@creditas/tokens';
import { Global } from '@creditas/global';
import { FlagsmithProvider } from 'flagsmith/react';
import flagsmith from 'flagsmith';
import configureStore from './store/configure-store';
import { AppCustomProps, defaultCustomProps } from '.';
import { CustomPropsProvider } from './contexts';
import { Routes } from './Routes';
import { envVars } from './envVars';

/* eslint-disable react/prefer-stateless-function */
class App extends Component<AppCustomProps> {
  render(): React.ReactElement {
    return (
      <FlagsmithProvider
        options={{
          environmentID: envVars.FLAGSMITH_ENVIRONMENT_ID,
        }}
        flagsmith={flagsmith}
      >
        <StoreContext.Provider value={configureStore()}>
          <CustomPropsProvider value={{ ...defaultCustomProps, ...this.props }}>
            <ThemeProvider theme={{ ...tokens }}>
              <Global />
              <Routes />
            </ThemeProvider>
          </CustomPropsProvider>
        </StoreContext.Provider>
      </FlagsmithProvider>
    );
  }
}
/* eslint-enable */

export default App;
