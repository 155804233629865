import { styled } from '@creditas/stylitas';
import { Button } from '@creditas/button';

export const FormContainer = styled.div`
  margin: 70px;
`;

export const FlexEnd = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
`;

export const ListButton = styled(Button)`
  margin-right: 10px;
`;

export const FeedbackMessageBox = styled.div`
  margin: 15px;
  width: calc(100% - 30px);
  position: absolute;
  box-shadow: 5px 10px 8px #888888;
  z-index: 1;
  white-space: pre-wrap;
`;
