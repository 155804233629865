/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from 'react';
import { useField } from 'formik';
import { InputField } from '@creditas/form-elements';
import { Grid } from '@creditas/layout';
import { Typography } from '@creditas/typography';
import { Fieldset } from './style';

type TextInputProps = {
  name: string;
  label: string;
  onChange: any;
  fieldValue?: any;
  layout?: [number?, number?];
  disabled?: boolean;
  error?: string;
  touched?: boolean;
  colStart?: number;
  colEnd?: number;
  type?: string;
  pattern?: string;
  noGrid?: boolean;
  width?: string;
  dataTestId?: string;
};

export const TextInput = ({
  name,
  label,
  onChange,
  fieldValue,
  disabled,
  error,
  touched,
  colStart,
  colEnd,
  type,
  pattern,
  noGrid,
  width,
  dataTestId,
}: TextInputProps) => {
  const [textField] = useField<string | undefined>(label);
  if (!fieldValue) {
    // eslint-disable-next-line no-param-reassign
    fieldValue = '';
  }
  if (noGrid) {
    return (
      <Fieldset>
        <InputField
          {...textField}
          onChange={onChange}
          data-testid={dataTestId}
          name={name}
          label={label}
          disabled={disabled}
          value={`${fieldValue}`}
          width={width}
          type={type || 'text'}
          variant={error && touched ? 'danger' : 'default'}
          pattern={pattern}
        />
        {error && touched ? (
          <Typography color="danger" variant="support">
            {error}
          </Typography>
        ) : null}
      </Fieldset>
    );
  }
  return (
    <Grid item colStart={colStart} colEnd={colEnd || 3}>
      <Fieldset>
        <InputField
          {...textField}
          data-testid={dataTestId}
          onChange={onChange}
          name={name}
          label={label}
          disabled={disabled}
          value={`${fieldValue}`}
          width=""
          type={type || 'text'}
          variant={error && touched ? 'danger' : 'default'}
          pattern={pattern}
        />
        {error && touched ? (
          <Typography color="danger" variant="support">
            {error}
          </Typography>
        ) : null}
      </Fieldset>
    </Grid>
  );
};
