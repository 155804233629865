import React, { ChangeEvent, useEffect, useState } from 'react';
import { Typography } from '@creditas/typography';
import { TextField, RadioInput } from '@creditas/input';
import { styled } from '@creditas/stylitas';
import { CnabValues } from '../../pages/FormCnabGenerate/FormCnabGenerate';
import { capitalizeFirstLetter } from '../../utils/string';

const FlexContainer = styled.h1`
  display: flex;
  flex-direction: row;
`;

const FlexChild = styled.div`
  flex: 0 0 auto;
  margin: 10px;
`;

interface CnabModel {
  value: CnabValues;
  callbacks: {
    setProduct: (value: string) => void;
    setModel: (value: string) => void;
    setCnabModelFilled: (value: boolean) => void;
  };
}

const PRODUCTS = [
  'Home',
  'Auto / Moto',
  'AutoRefin (Sorocred)',
  'Consignado',
  'Previdência Privada',
  'Outros',
];

const layoutTypes: { [key: string]: string[] } = {
  Home: [
    'CNAB 500 Online',
    'CNAB 550 Online SMART FIDC',
    'CNAB 550 Offline SMART FIDC',
    'JSON Online',
    'JSON Offline',
  ],
  'Auto / Moto': [
    'CNAB 550 Online Fromtis',
    'CNAB 550 Online SMART FIDC',
    'CNAB 550 Offline SMART FIDC',
    'JSON Online',
    'JSON Offline',
    'JSON Offline Floorplan',
  ],
  'AutoRefin (Sorocred)': ['CNAB 550 Online SMART FIDC', 'JSON Online'],
  'Previdência Privada': [
    'JSON Offline',
    'CNAB 550 Offline SMART FIDC',
    'CNAB 550 Online SMART FIDC',
  ],
  Consignado: [
    'JSON Online',
    'JSON Offline',
    'CNAB 550 Offline SMART FIDC',
    'CNAB 550 Online SMART FIDC',
  ],
  Outros: [
    'CNAB 500 Online',
    'CNAB 550 Offline SMART FIDC',
    'CNAB 550 Online SMART FIDC',
    'CNAB 550 Online Fromtis',
    'JSON Online',
    'JSON Offline',
  ],
};

const layoutsValues: { [key: string]: string } = {
  'CNAB 500 Online': 'CNAB_500_ONLINE',
  'CNAB 550 Offline SMART FIDC': 'CNAB_550_OFFLINE',
  'CNAB 550 Online SMART FIDC': 'CNAB_550_ONLINE_SMARTFIDC',
  'CNAB 550 Online Fromtis': 'CNAB_550_ONLINE_FROMTIS',
  'JSON Online': 'JSON_ONLINE',
  'JSON Offline': 'JSON_OFFLINE',
  'JSON Offline Floorplan': 'JSON_BRL_ALOHA_FLOORPLAN',
};

export const CnabModel: React.FC<CnabModel> = ({ value, callbacks }) => {
  useEffect(() => {
    callbacks.setCnabModelFilled(value.model !== '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.model]);

  const [layouts, setLayouts] = useState(['']);

  const handleProductSelect = (product: string) => {
    const selectedLayouts = product !== '' ? layoutTypes[product] : [''];
    setLayouts(selectedLayouts);
  };

  return (
    <>
      <Typography color="black" ariant="h6">
        Modelo
      </Typography>
      <Typography color="black" variant="form">
        Selecione um tipo de produto:
      </Typography>
      <FlexContainer>
        {PRODUCTS.map((product: string) => (
          <FlexChild>
            <RadioInput
              id={product}
              label={capitalizeFirstLetter(product)}
              onChange={(): void => {
                handleProductSelect(product);
                callbacks.setProduct(product);
              }}
              value={product}
              checked={product === value.product}
            />
          </FlexChild>
        ))}

        <TextField
          label="Modelo do CNAB"
          select
          onChange={(e: ChangeEvent<HTMLSelectElement>): void => {
            callbacks.setModel(e.target.value);
          }}
          disabled={value.product === ''}
        >
          <option>Selecione um modelo do CNAB</option>
          {layouts.map(layout => (
            <option value={layoutsValues[layout]}>{layout}</option>
          ))}
        </TextField>
      </FlexContainer>
    </>
  );
};
