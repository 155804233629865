import React, { useState } from 'react';
import { Action } from '@creditas/icons';
import { Modal } from '../../../../modules';
import { AssignmentValidation } from '../AssignmentValidation';

interface AssignmentCancelationButtonProps {
  state: string;
  assignmentId: string;
  updateAssignmentState: (assignmentId: string, state: string) => Promise<void>;
}

export const AssignmentCancelation: React.FC<AssignmentCancelationButtonProps> = ({
  state,
  assignmentId,
  updateAssignmentState,
}) => {
  const [showCancelModal, setShowCancelModal] = useState(false);

  return (
    <>
      {state === 'ERROR' ||
      state === 'CANCELED' ||
      state === 'PROCESSING' ||
      state === 'CONFIRMED' ||
      state === 'SUBMISSION_ERROR' ? (
        <>
          <Action.IconSystemClose data-testid="assignment-cancel" fontSize="large" />
        </>
      ) : (
        <>
          <Action.IconSystemClose
            data-testid="assignment-cancel"
            fontSize="large"
            color="danger"
            onClick={() => setShowCancelModal(true)}
          />
          <Modal canShow={showCancelModal} hide={() => setShowCancelModal(false)}>
            <AssignmentValidation
              action={() => updateAssignmentState(assignmentId, 'CANCELED')}
              closeModal={() => setShowCancelModal(false)}
              question="Deseja realmente cancelar a cessão ?"
            />
          </Modal>
        </>
      )}
    </>
  );
};
