import React, { useState, useContext } from 'react';

const initialState = {
  accounts: [],
  setAccounts: () => {},
};

export const AccountContext = React.createContext<any>(initialState);
export const AccountProvider = ({ children }) => {
  const [accounts, setAccounts] = useState([]);
  return (
    <AccountContext.Provider value={{ accounts, setAccounts }}>{children}</AccountContext.Provider>
  );
};

const useAccount = () => {
  const context = useContext(AccountContext);

  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

export default useAccount;
