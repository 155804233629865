/* eslint-disable no-useless-escape */
import * as Yup from 'yup';
import { CategoriesType } from './form-attributes';

const cnpjRegex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/;

export const Validation = Yup.object().shape({
  name: Yup.string().required('Nome do credor é obrigatório'),
  nickname: Yup.string().required('Apelido é obrigatório'),
  simplifiedName: Yup.string().required('Nome simplificado é obrigatório '),
  manager: Yup.string().when(['category'], {
    is: (category: string) => category !== CategoriesType.UNDERWRITER.toString(),
    then: Yup.string().required('Manager é obrigatório'),
    otherwise: Yup.string().notRequired(),
  }),
  custodian: Yup.string().when(['category'], {
    is: (category: CategoriesType) => category !== CategoriesType.UNDERWRITER,
    then: Yup.string().required('Custodiante é obrigatório'),
    otherwise: Yup.string().notRequired(),
  }),
  administrator: Yup.string().when(['category'], {
    is: (category: CategoriesType) => category !== CategoriesType.UNDERWRITER,
    then: Yup.string().required('Administrador é obrigatório'),
    otherwise: Yup.string().notRequired(),
  }),
  activatedAt: Yup.date().required('Data de Origem é obrigatório'),
  seniorSharesExpirationDate: Yup.date().required('Data da Cota Sênior é obrigatória'),
  cnpj: Yup.string()
    .required('CNPJ é obrigatório')
    .matches(cnpjRegex, 'CNPJ inválido (ex: 11.222.333/0001-44)'),
  interestRateBase: Yup.number().when(['category'], {
    is: (category: CategoriesType) => category !== CategoriesType.UNDERWRITER,
    then: Yup.number()
      .typeError('Esse campo deve ser numerico')
      .required('Base de cálculo é obrigatório')
      .positive('Base de cálculo deve ser um numero positivo '),
    otherwise: Yup.number().notRequired(),
  }),
  category: Yup.string().required('Categoria é obrigatório'),
});

export const ValidationAccount = Yup.object().shape({
  bankCode: Yup.number()
    .typeError('Esse campo deve ser numerico')
    .required('Código do banco é obrigatório')
    .positive('Código do banco deve ser um numero positivo ')
    .integer(),
  bankName: Yup.string().required('Nome do banco é obrigatório'),
  branchNumber: Yup.string().required('Número da agencia é obrigatório'),
  accountNumber: Yup.string().required('Número da conta é obrigatório'),
  accountDigit: Yup.string().required('Digito da conta é obrigatório'),
  product: Yup.string().required('Produto é obrigatório'),
  type: Yup.string().required('Tipo é obrigatório'),
});
