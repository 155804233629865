export interface Assignment {
  id: string;
  underwriter: string;
  assignor: string;
  assignee: string;
  assignedAt: string;
  endorser: string;
  assignmentCode: string;
  assignmentFee: number;
  numberOfInstallments: number;
  numberOfLoans: number;
  assignmentValue: number;
  files: Array<AssignmentFile>;
  state: string;
  layoutName: string;
  type: string | null;
  createdAt: string;
}

export interface Sort {
  unsorted: boolean;
  sorted: boolean;
  empty: boolean;
}

export interface Pageable {
  sort: Sort;
  pageSize: number;
  pageNumber: number;
  offset: number;
  paged: boolean;
  unpaged: boolean;
}
export interface AssignmentResponse {
  content: Assignment[];
  pageable: Pageable;
  totalPages: number;
  totalElements: number;
  last: boolean;
  first: boolean;
  sort: Sort;
  number: number;
  numberOfElements: number;
  size: number;
  empty: boolean;
}

export interface AssignmentFile {
  link: string;
  type: FileType;
}

export enum FileType {
  ASSIGNMENT_INCLUSION_OPERATION = 'ASSIGNMENT_INCLUSION_OPERATION',
  ASSIGNMENT_REMOVAL_OPERATION = 'ASSIGNMENT_REMOVAL_OPERATION',
  CREDITOR_EXCHANGE = 'CREDITOR_EXCHANGE',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
}

export enum AssignmentStatus {
  WAITING_CONFIRMATION = 'Aguardando confirmação',
  PROCESSING = 'Processando',
  CONFIRMED = 'Confirmada',
  ERROR = 'Erro',
  CANCELED = 'Cancelada',
  REQUESTED = 'Solicitado',
  SUBMISSION_ERROR = 'Erro de envio',
}
