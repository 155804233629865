import React from 'react';

import { TableRow } from '@creditas/table';
import { PaginationTable } from '@creditas/pagination';
import { Grid } from '@creditas/layout';

import { StyledCreditasTable, TableCell, TableHead, TableBody } from './Table.style';
import { Assignment, AssignmentResponse, FileType } from '../../../../types';
import { AssignmentState } from '../AssignmentState';
import { AssignmentDownloadFileButton } from '../AssignmentDownloadFileButton';
import { AssignmentConfirmation } from '../AssignmentConfirmation';
import { AssignmentCancelation } from '../AssignmentCancelation';

type updateType = 'replace' | 'replaceIn' | 'push' | 'pushIn' | undefined;

interface TableProps {
  data: AssignmentResponse;
  page: number;
  pageSize: number;
  setPage: (page: number, updateType?: updateType) => void;
  setPageSize: (pageSize: number, updateType?: updateType) => void;
  updateAssignmentState: (assignmentId: string, state: string) => Promise<void>;
}

const HEADER_TITLES = [
  'Código de Cessão',
  'Cedente',
  'Cessionário',
  'Modelo',
  'Número de contratos',
  'Número de parcelas',
  'Valor da cessão',
  'Data da cessão',
  'Tipo da cessão',
  'Data de criação',
  'Status',
  'Ações',
];

export const Table: React.FC<TableProps> = ({
  data,
  page,
  pageSize,
  setPage,
  setPageSize,
  updateAssignmentState,
}) => {
  function formatValueWithThousands(value: string): string {
    if (value == null) {
      return '-';
    }
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
  function formatValueWithDecimals(value: number): string {
    return value.toFixed(2).replace('.', ',');
  }

  function formatValueMoney(value: number): string {
    if (value == null) {
      return '-';
    }
    const formattedValueWithDecimals = formatValueWithDecimals(value);
    const formattedValueWithDecimalsAndThousands = formatValueWithThousands(
      formattedValueWithDecimals,
    );
    return `R$ ${formattedValueWithDecimalsAndThousands}`;
  }

  function isPartialAssignment(assignment: Assignment): boolean {
    const { files } = assignment;
    return (
      files.some(file => file.type === FileType.ERROR) &&
      files.some(
        file =>
          file.type === FileType.ASSIGNMENT_INCLUSION_OPERATION ||
          file.type === FileType.ASSIGNMENT_REMOVAL_OPERATION,
      )
    );
  }

  function capitalizeAssignmentType(assignmentType: string | null): string {
    if (assignmentType == null) {
      return '-';
    }
    return assignmentType?.charAt(0) + assignmentType?.slice(1).toLowerCase();
  }

  return (
    <Grid container fixed>
      <Grid item colStart={1} colEnd={12}>
        <StyledCreditasTable fullWidth>
          <TableHead>
            <TableRow>
              {HEADER_TITLES.map(headerTitle => (
                <TableCell textAlign="left" key={headerTitle}>
                  {headerTitle}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody variant="striped">
            {data.content.map(assignment => (
              <TableRow data-testid="table-row" key={assignment.id}>
                <TableCell>{assignment.assignmentCode}</TableCell>
                <TableCell>{assignment.assignor}</TableCell>
                <TableCell>{assignment.assignee}</TableCell>
                <TableCell>{assignment.layoutName}</TableCell>
                <TableCell>
                  {formatValueWithThousands(assignment.numberOfLoans?.toString())}
                </TableCell>
                <TableCell>
                  {formatValueWithThousands(assignment.numberOfInstallments?.toString())}
                </TableCell>
                <TableCell>{formatValueMoney(assignment.assignmentValue)}</TableCell>
                <TableCell>{assignment.assignedAt}</TableCell>
                <TableCell>{capitalizeAssignmentType(assignment.type)}</TableCell>
                <TableCell>{assignment.createdAt}</TableCell>
                <TableCell>
                  <AssignmentState
                    value={assignment.state}
                    isPartialAssignment={isPartialAssignment(assignment)}
                  />
                </TableCell>
                <TableCell>
                  <AssignmentDownloadFileButton files={assignment.files} state={assignment.state} />
                  <AssignmentConfirmation
                    state={assignment.state}
                    assignmentId={assignment.id}
                    updateAssignmentState={updateAssignmentState}
                  />
                  <AssignmentCancelation
                    state={assignment.state}
                    assignmentId={assignment.id}
                    updateAssignmentState={updateAssignmentState}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledCreditasTable>
      </Grid>
      <Grid item colStart={1} colEnd={12}>
        <PaginationTable
          current={page}
          pageSize={pageSize}
          total={data.totalPages}
          onChangePage={(newPage: number): void => setPage(newPage, 'pushIn')}
          onChangePageSize={(newPageSize: number): void => {
            setPageSize(newPageSize, 'pushIn');
            setPage(1, 'replaceIn');
          }}
        />
      </Grid>
    </Grid>
  );
};
