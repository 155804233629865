import React, { useState } from 'react';
import { FeedbackMessage } from '@creditas/feedback-message';
import { FormAttributesAccount } from './form-attributes';
import { FormAccount } from './FormAccount';
import { FormContainer } from '../../../modules/FormAttributes/style';
import AccountService, { Account } from '../../../services/Account';
import useAccount from '../../../contexts/AccountContext';

type EditAccountProps = {
  fundPlatformId: string;
  account: Account;
};

interface FeedbackMessage {
  show: boolean;
  message?: string;
  variant?: 'success' | 'danger';
}

const accountAPI = new AccountService(localStorage.getItem('greencare-access-token'));

export const EditAccount = ({ fundPlatformId, account }: EditAccountProps) => {
  const { accounts, setAccounts } = useAccount();
  const [feedbackMessage, setFeedbackMessage] = useState<FeedbackMessage>({
    show: false,
    message: '',
    variant: 'danger',
  });
  const sendData = async (values: FormAttributesAccount) => {
    try {
      const submitValues = {
        ...values,
      };
      await accountAPI.update(account.platformId!, fundPlatformId, submitValues);
      const accountUpdate = {
        platformId: account.platformId,
        ...submitValues,
      };
      const newAccounts = accounts.map((it: Account) => {
        if (it.platformId === accountUpdate.platformId) return accountUpdate;
        return it;
      });
      setAccounts([...newAccounts]);
      setFeedbackMessage({
        show: true,
        message: 'Conta bancária atualizada com sucesso',
        variant: 'success',
      });
    } catch (err) {
      console.error(err);
      setFeedbackMessage({
        show: true,
        message: 'Error ao atualizar conta bancária',
        variant: 'danger',
      });
    }
  };

  return (
    <FormContainer>
      {feedbackMessage.show ? (
        <>
          <FeedbackMessage variant={feedbackMessage.variant}>
            {feedbackMessage.message}
          </FeedbackMessage>
          <br />
        </>
      ) : (
        <></>
      )}
      <FormAccount sendData={sendData} formValues={account} />
    </FormContainer>
  );
};
