import React, { useEffect, useState } from 'react';

import { Table, TableBody, TableHead, TableRow } from '@creditas/table';
import { TableCell as TableCellOriginal } from '@creditas/table/dist/TableCell';

import { styled } from '@creditas/stylitas';
import { Action, Editor } from '@creditas/icons';
import { Button } from '@creditas/button';
import { Modal } from '../../../modules/index';
import { CreateAccount } from './CreateAccount';
import { Account, AccountType } from '../../../services/Account';
import { EditAccount } from './EditAccount';
import useAccount from '../../../contexts/AccountContext';

const TableCell = styled(TableCellOriginal)`
  vertical-align: middle;
`;

const NewButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100px;
  margin-left: auto;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

type AccountsProps = {
  fundPlatformId: string;
  accounts?: Account[];
};

export const Accounts = ({ fundPlatformId, accounts }: AccountsProps) => {
  const [createAccountModal, setCreateAccountModal] = useState<boolean>(false);
  const [editAccountModal, setEditAccountModal] = useState<boolean>(false);
  const [accountEdit, setAccountEdit] = useState<Account>({
    product: '',
    type: 'DISBURSEMENT',
    bankCode: '',
    bankName: '',
    branchNumber: '',
    accountNumber: '',
    accountDigit: '',
  });
  const [accountCopy, setAccountCopy] = useState<Account>();
  const [accountsState, setAccountsState] = useState<Array<Account>>(accounts!);
  const { accounts: accountsHook, setAccounts } = useAccount();
  useEffect(() => {
    setAccountsState(accountsHook);
  }, [accountsHook]);
  useEffect(() => {
    setAccounts(accounts);
  }, [accounts, setAccounts]);

  function mountTable() {
    return accountsState?.map(account => {
      return (
        <TableRow key={account.platformId}>
          <TableCell>{account.product}</TableCell>
          <TableCell>{AccountType.description(account.type)}</TableCell>
          <TableCell>{account.bankName}</TableCell>
          <TableCell>{account.bankCode}</TableCell>
          <TableCell>{account.branchNumber}</TableCell>
          <TableCell>{account.accountNumber}</TableCell>
          <TableCell>{account.accountDigit}</TableCell>
          <TableCell>
            <a
              href={`${window.location.pathname}/edicao/${account.platformId}`}
              onClick={e => {
                e.preventDefault();
                setAccountEdit(account);
                setAccounts(accounts);
                setEditAccountModal(true);
              }}
            >
              <Editor.Edit color="primary02" />
            </a>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              onClick={e => {
                setCreateAccountModal(true);
                setAccountCopy(account);
                e.preventDefault();
              }}
            >
              <Action.Copy color="info" />
            </a>
          </TableCell>
        </TableRow>
      );
    });
  }

  return (
    <Container>
      <NewButton onClick={() => setCreateAccountModal(true)}>
        Adicionar <Action.PlusCircle />
      </NewButton>

      <Table fullWidth>
        <TableHead>
          <TableRow>
            <TableCell>Produto</TableCell>
            <TableCell>Tipo</TableCell>
            <TableCell>Nome do Banco</TableCell>
            <TableCell>Código do Banco</TableCell>
            <TableCell>Agência</TableCell>
            <TableCell>Número</TableCell>
            <TableCell>Digito</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody variant="striped">{mountTable()}</TableBody>
      </Table>
      <Modal canShow={createAccountModal} hide={() => setCreateAccountModal(false)}>
        <CreateAccount fundPlatformId={fundPlatformId} accountCopy={accountCopy} />
      </Modal>
      <Modal canShow={editAccountModal} hide={() => setEditAccountModal(false)}>
        <EditAccount fundPlatformId={fundPlatformId} account={accountEdit} />
      </Modal>
    </Container>
  );
};
