import { Button } from '@creditas/button';
import { Typography } from '@creditas/typography';
import React from 'react';
import { ConfimationContainer } from './AssignmentValidation.style';

interface AssignmentValidationProps {
  question: string;
  action: () => void;
  closeModal: () => void;
}

export const AssignmentValidation: React.FC<AssignmentValidationProps> = ({
  action,
  question,
  closeModal,
}) => {
  return (
    <>
      <ConfimationContainer>
        <Typography>{question}</Typography>
      </ConfimationContainer>
      <ConfimationContainer>
        <Button data-testid="deny-action" secondary onClick={closeModal}>
          Não
        </Button>
        <Button
          data-testid="accept-action"
          onClick={() => {
            action();
            closeModal();
          }}
        >
          Sim
        </Button>
      </ConfimationContainer>
    </>
  );
};
