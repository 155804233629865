import React, { useState } from 'react';
import { Action } from '@creditas/icons';
import { Modal } from '../../../../modules';
import { AssignmentValidation } from '../AssignmentValidation';

interface AssignmentConfirmButtonProps {
  state: string;
  assignmentId: string;
  updateAssignmentState: (assignmentId: string, state: string) => Promise<void>;
}

export const AssignmentConfirmation: React.FC<AssignmentConfirmButtonProps> = ({
  state,
  assignmentId,
  updateAssignmentState,
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  return (
    <>
      {state !== 'WAITING_CONFIRMATION' ? (
        <>
          <Action.Check data-testid="assignment-confirm" fontSize="large" />
        </>
      ) : (
        <>
          <Action.Check
            data-testid="assignment-confirm"
            fontSize="large"
            color="success"
            onClick={() => setShowConfirmModal(true)}
          />
          <Modal canShow={showConfirmModal} hide={() => setShowConfirmModal(false)}>
            <AssignmentValidation
              action={() => updateAssignmentState(assignmentId, 'CONFIRMED')}
              closeModal={() => setShowConfirmModal(false)}
              question="Deseja realmente confirmar a cessão ?"
            />
          </Modal>
        </>
      )}
    </>
  );
};
