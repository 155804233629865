import React, { ChangeEvent, useState } from 'react';
import { Typography } from '@creditas/typography';
import { TextField } from '@creditas/input';
import { Button } from '@creditas/button';
import { FlexChild, FlexContainer, divStyle } from './Sheet.style';

type FeedbackMessageProps = {
  error: boolean;
  success: boolean;
  info: boolean;
  message: string;
};
interface Sheet {
  setFeedbackMessage: ({ error, success, info, message }: FeedbackMessageProps) => void;
  enableSection: boolean;
  callbacks: {
    setFileContent: (value: string) => void;
  };
}

export const Sheet: React.FC<Sheet> = ({ setFeedbackMessage, enableSection, callbacks }) => {
  const [fileName, setFileName] = useState('');

  const buttonFile = enableSection ? (
    <Button secondary disabled component="span">
      Escolha um arquivo
    </Button>
  ) : (
    <Button secondary disabled>
      Escolha um arquivo
    </Button>
  );

  return (
    <>
      <Typography color="black" ariant="h6">
        Planilha
      </Typography>
      <Typography color="black" variant="form">
        Selecione uma planilha .csv para importar
      </Typography>
      <FlexContainer>
        <FlexChild>
          <input
            accept=".csv"
            type="file"
            style={divStyle}
            id="contained-button-file"
            onChange={(e: ChangeEvent<HTMLInputElement>): void => {
              const file = e.target.value.replace('C:\\fakepath\\', '');
              const fileExtension = file.split('.').pop();
              setFileName(file);
              if (fileExtension === 'csv') {
                const { files } = e.target;
                if (files) {
                  const reader = new FileReader();
                  reader.readAsText(files[0]);
                  reader.onload = (readEvent): void => {
                    if (readEvent.target && readEvent.target.result) {
                      callbacks.setFileContent(readEvent.target.result.toString());
                    }
                  };
                }
              } else {
                setFeedbackMessage({
                  success: false,
                  error: true,
                  info: false,
                  message: 'Arquivo inválido. Favor, inserir um arquivo csv',
                });
              }
            }}
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="contained-button-file">{buttonFile}</label>
        </FlexChild>
        <TextField id="value" placeholder={fileName} disabled />
      </FlexContainer>
    </>
  );
};
