import React, { useContext } from 'react';

import { Breadcrumb as Navigation, BreadcrumbItem } from '@creditas/breadcrumb';
import { Link } from 'react-router-dom';
import { CustomPropsContext } from '../../../contexts';

export type BreadcrumbItemProps = {
  text: string;
  link?: string;
};

type BreadcrumbProps = {
  navigation: Array<BreadcrumbItemProps>;
};

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ navigation }) => {
  const { basePath } = useContext(CustomPropsContext);

  return (
    <Navigation>
      {navigation.map((element, index) => (
        <BreadcrumbItem key={index.toString()}>
          {element.link ? (
            <Link
              to={`${basePath}${element.link}`}
              title={element.text}
              style={{ textDecoration: 'none' }}
              data-testid="link"
            >
              {element.text}
            </Link>
          ) : (
            element.text
          )}
        </BreadcrumbItem>
      ))}
    </Navigation>
  );
};
