import React, { useContext, useEffect, useState } from 'react';

import { generatePath, RouteComponentProps, useHistory } from 'react-router-dom';
import { Container } from '@creditas/layout';
import { Typography } from '@creditas/typography';
import { Spinner } from '@creditas/spinner';
import { FeedbackMessage } from '@creditas/feedback-message';

import { TabItem, Tabs } from '@creditas/tabs';

import { FormContainer, Title } from '../../modules/FormAttributes/style';
import { FormFundRegistration } from './components/FormFundRegistration';
import FundService, { FundValuesResponse } from '../../services/Fund';
import { FormAttributes } from './components/form-attributes';
import { Breadcrumb } from './components/Breadcrumb';
import { CustomPropsContext } from '../../contexts';
import { Accounts } from './components/Accounts';
import { AccountProvider } from '../../contexts/AccountContext';

const fundAPI = new FundService(localStorage.getItem('greencare-access-token'));

interface MatchParams {
  id: string;
  step: string;
}

type Props = RouteComponentProps<MatchParams>;

export const FundRegistrationEdit = ({
  match: {
    params: { id, step },
  },
}: Props) => {
  const [data, setData] = useState<FundValuesResponse>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [tabRegistration, setTabRegistration] = useState<string>('selected');
  const [tabAccount, setTabAccount] = useState<string>('');

  const routerHistory = useHistory();

  const { basePath } = useContext(CustomPropsContext);

  function clickedTab(tab: string) {
    if (tab === 'registration') {
      setTabAccount('');
      setTabRegistration('selected');
    }
    if (tab === 'accounts') {
      setTabAccount('selected');
      setTabRegistration('');
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        clickedTab(step);
        setLoading(true);
        const response = await fundAPI.getById(id);
        setData(response.data);
      } catch (err) {
        setError(`error ao carregar credor ${id} ${err?.message}`);
        setData(undefined);
      }
    };

    fetchData();
    setLoading(false);
  }, [id, step]);

  const sendData = async (values: FormAttributes) => {
    try {
      const submitValues = {
        ...values,
        interestRateBase: Number(values.interestRateBase),
      };
      if (!data?.platformId) throw Error('platformId is not defined');
      const response = await fundAPI.update(data.platformId, submitValues);
      setData(response.data);
      alert(`Credor ${values.name} atualizado com sucesso. id: ${response.data.platformId}`);
      routerHistory.push(`${basePath}/credores/edicao/${response.data.platformId}`);
    } catch (err) {
      setError(`erro ao salvar dados ${err?.message}`);
    }
  };

  const navigation = [{ text: 'Credores', link: generatePath('/credores') }, { text: 'Editar' }];

  return (
    <AccountProvider>
      <Container maxWidth="lg">
        <FormContainer>
          <Breadcrumb navigation={navigation} />
          <Title>
            <Typography variant="title">Registro de Credor</Typography>
          </Title>
          {error && <FeedbackMessage variant="danger">{error}</FeedbackMessage>}
          {loading && <Spinner message="Carregando..." />}
          {data && (
            <>
              <Tabs>
                <TabItem onClick={() => clickedTab('registration')} variant={tabRegistration}>
                  Dados Cadastrais
                </TabItem>
                <TabItem onClick={() => clickedTab('accounts')} variant={tabAccount}>
                  Dados Bancários
                </TabItem>
              </Tabs>
              <br />
              <br />
              {tabRegistration !== '' ? (
                <FormFundRegistration formValues={data} sendData={sendData} messageError={error} />
              ) : (
                <Accounts fundPlatformId={id} accounts={data?.accounts} />
              )}
            </>
          )}
        </FormContainer>
      </Container>
    </AccountProvider>
  );
};
