import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { envVars } from '../envVars';
import { responseErrorInterceptor } from './Interceptors';

export default class FileEngine {
  private authToken: string | null;

  private httpClient: AxiosInstance;

  constructor(authToken: string | null) {
    this.authToken = authToken;
    this.httpClient = axios.create();
    this.httpClient.interceptors.response.use(response => response, responseErrorInterceptor);
  }

  generateCnab = (formData: FormData): Promise<AxiosResponse> => {
    const config = {
      headers: {
        'Content-Type': `multipart/form-data`,
        Authorization: `Bearer ${this.authToken}`,
        Accept: 'application/vnd.creditas.v1+json',
      },
    };

    const fundUrl = `${envVars.FILE_ENGINE_APP_URL}/assignments/files`;

    return this.httpClient.post(fundUrl, formData, config);
  };
}
