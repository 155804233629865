import React from 'react';
import { Button } from '@creditas/button';
import { FeedbackMessage } from '@creditas/feedback-message';
import { Form, Formik } from 'formik';
import { styled } from '@creditas/stylitas';
import { ValidationAccount } from './validation';
import { MarginTop } from '../../../modules/FormAttributes/style';
import { FormAttributesAccount, startFormAccount } from './form-attributes';
import { TextInput } from '../../../modules/FormAttributes/InputField';
import { SelectField } from '../../../modules/FormAttributes/SelectField';
import { AccountType } from '../../../services/Account';

const StyledFeedbackMessage = styled(FeedbackMessage)`
  margin-top: 36px;
`;

const RowCustom = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

type FormAccountProps = {
  sendData: (values: FormAttributesAccount) => Promise<void>;
  formValues?: Account;
  messageError?: string;
};

const productOptions = [
  {
    value: 'AUTO',
    text: 'AUTO ',
  },
  {
    value: 'HOME',
    text: 'HOME ',
  },
  {
    value: 'PENSION',
    text: 'PENSION ',
  },
  {
    value: 'PAYROLL',
    text: 'PAYROLL ',
  },
];

export const FormAccount = ({ sendData, formValues, messageError }: FormAccountProps) => {
  return (
    <Formik
      initialValues={startFormAccount(formValues)}
      validationSchema={ValidationAccount}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        await sendData(values);
        setSubmitting(false);
      }}
    >
      {({ values, errors, touched, isSubmitting, handleChange }) => (
        <Form>
          <RowCustom>
            <SelectField
              noGrid
              width="240px"
              dataTestId="product"
              name="product"
              onChange={handleChange}
              label="Produto"
              options={productOptions}
              touched={touched.product}
              error={errors.product}
              value={values.product}
            />
            <SelectField
              noGrid
              width="240px"
              dataTestId="type"
              name="type"
              onChange={handleChange}
              label="Tipo"
              options={AccountType.options}
              touched={touched.type}
              error={errors.type}
              value={values.type}
            />
          </RowCustom>
          <RowCustom>
            <TextInput
              noGrid
              dataTestId="bankCode"
              width="240px"
              name="bankCode"
              label="Codigo do Banco"
              error={errors.bankCode}
              touched={touched.bankCode}
              onChange={handleChange}
              fieldValue={values.bankCode}
            />
            <TextInput
              noGrid
              width="240px"
              name="bankName"
              dataTestId="bankName"
              label="Nome do Banco"
              error={errors.bankName}
              touched={touched.bankName}
              onChange={handleChange}
              fieldValue={values.bankName}
            />
          </RowCustom>
          <RowCustom>
            <TextInput
              noGrid
              width="240px"
              name="branchNumber"
              dataTestId="branchNumber"
              label="Número da Agência"
              error={errors.branchNumber}
              touched={touched.branchNumber}
              onChange={handleChange}
              fieldValue={values.branchNumber}
            />
            <TextInput
              noGrid
              width="240px"
              name="accountNumber"
              label="Numero da Conta"
              dataTestId="accountNumber"
              error={errors.accountNumber}
              touched={touched.accountNumber}
              onChange={handleChange}
              fieldValue={values.accountNumber}
            />
          </RowCustom>
          <RowCustom>
            <TextInput
              noGrid
              width="240px"
              name="accountDigit"
              label="Digito da Conta"
              dataTestId="accountDigit"
              error={errors.accountDigit}
              touched={touched.accountDigit}
              onChange={handleChange}
              fieldValue={values.accountDigit}
            />
          </RowCustom>
          {messageError && (
            <StyledFeedbackMessage variant="danger">{messageError}</StyledFeedbackMessage>
          )}

          <MarginTop>
            <Button type="submit" fullWidth isLoading={isSubmitting} disabled={isSubmitting}>
              Salvar
            </Button>
          </MarginTop>
        </Form>
      )}
    </Formik>
  );
};
