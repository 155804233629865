import * as React from 'react';
import { InputSelect } from '@creditas/form-elements';
import { Grid } from '@creditas/layout';
import { Typography } from '@creditas/typography';
import { Fieldset } from './style';

type SelectFieldProps = {
  name?: string;
  label: string;
  onChange?: any;
  error?: string;
  touched?: string;
  colStart?: number;
  colEnd?: number;
  options: Array<{ value: string; text: string }>;
  value?: string;
  noGrid?: boolean;
  width?: string;
  dataTestId?: string;
};

export const SelectField = ({
  name,
  label,
  onChange,
  colStart,
  colEnd,
  options,
  value,
  noGrid,
  width,
  error,
  touched,
  dataTestId,
}: SelectFieldProps) => {
  if (noGrid) {
    return (
      <Fieldset>
        <InputSelect
          data-testid={dataTestId}
          label={label}
          name={name}
          value={value}
          onChange={onChange}
          options={options}
          width={width}
          fieldset="fieldset"
        />
        {error && touched ? (
          <Typography color="danger" variant="support">
            {error}
          </Typography>
        ) : null}
      </Fieldset>
    );
  }
  return (
    <Grid item colStart={colStart} colEnd={colEnd || 4}>
      <Fieldset>
        <InputSelect
          label={label}
          data-testid={dataTestId}
          name={name}
          value={value}
          onChange={onChange}
          options={options}
          fieldset="fieldset"
        />
        {error && touched ? (
          <Typography color="danger" variant="support">
            {error}
          </Typography>
        ) : null}
      </Fieldset>
    </Grid>
  );
};
