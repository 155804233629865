import axios, { AxiosInstance } from 'axios';
import { requestInterceptor, responseErrorInterceptor } from '../Interceptors';
import { envVars } from '../../envVars';

export const api: AxiosInstance = axios.create({
  baseURL: envVars.FILE_ENGINE_APP_URL,
});

api.interceptors.request.use(requestInterceptor);
api.interceptors.response.use(response => response.data, responseErrorInterceptor);
