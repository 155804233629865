import { styled } from '@creditas/stylitas';
import {
  Table as CreditasTable,
  TableHead as CreditasTableHead,
  TableCell as CreditasTableCell,
  TableBody as CreditasTableBody,
} from '@creditas/table';
import { Theme } from '../../../../interfaces/theme';

export const StyledCreditasTable = styled(CreditasTable)`
  table-layout: fixed;
  border: 1px solid #eee;
  width: 100%;
`;

export const TableCell = styled(CreditasTableCell)`
  text-align: center;
  word-wrap: break-word;
  vertical-align: middle;

  &:nth-of-type(11) {
    width: 20em;
  }

  &:last-child {
    width: 11em;
  }
`;

export const TableHead = styled(CreditasTableHead)`
  font-weight: 600;
  text-align: center;
`;

export const TableBody = styled(CreditasTableBody)`
  tr:hover {
    outline: 1px solid ${({ theme }: { theme: Theme }) => theme.bColorsecondary05};
    box-shadow: 0 10px 25px 0 ${({ theme }: { theme: Theme }) => theme.bColorneutral05};
  }
`;
