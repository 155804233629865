import { FeedbackMessage } from '@creditas/feedback-message';
import { styled } from '@creditas/stylitas';

export const Fieldset = styled.fieldset<{ disabled?: boolean; noGutter?: boolean }, any>`
  border: none;
  ${({ noGutter }) =>
    !noGutter &&
    `
      margin-bottom: 32px;
    `}
  ${({ disabled }) =>
    disabled &&
    `
    filter: brightness(97%) grayscale();
    pointer-events: none;
  `}

  select {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }
`;

export const Message = styled(FeedbackMessage)<Record<string, any>, any>`
  transform: translate(0, -30%);
  line-height: 1.5;
`;

export const MarginTop = styled.div`
  margin-top: 30px;
`;

export const Title = styled.div`
  margin-top: 20px;
  margin-bottom: 60px;
`;

export const FormContainer = styled.div`
  margin: 70px;
`;
