import React, { useState } from 'react';
import { Button } from '@creditas/button';
import { FeedbackMessage } from '@creditas/feedback-message';
import { AxiosError } from 'axios';
import { useAsyncCallback } from 'react-async-hook';
import { FormContainer, ListButton, FeedbackMessageBox, FlexEnd } from './FormCnabGenerate.style';
import { CnabModel, Detail, Sheet } from '../../modules';
import FileEngine from '../../services/FileEngine';

const fileEngineAPI = new FileEngine(localStorage.getItem('greencare-access-token'));

export interface CnabValues {
  product: string;
  model: string;
  filled: boolean;
}

export interface DetailValues {
  underwriterPlatformId: string;
  assignorPlatformId: string;
  assigneePlatformId: string;
  endorser: string;
  assignedAt: string;
  assignmentCode: string;
  assignmentFee: string;
  filled: boolean;
  receivableType: string;
  allowPartialAssignment?: boolean;
  modifyingType: string;
  modifyingFactor: string | null;
  isRepurchase: boolean;
}

export interface SheetValues {
  fileContent: string;
}

function setBlob(fileContent: string): Blob {
  const bytes = new TextEncoder().encode(fileContent);
  return new Blob([bytes], {
    type: 'application/json;charset=utf-8',
  });
}

const mapProductType: {
  [key: string]: string;
} = {
  Home: 'HOME',
  Consignado: 'PAYROLL',
  'Previdência Privada': 'PENSION',
  'AutoRefin (Sorocred)': 'AUTO',
  'Auto / Moto': 'AUTO',
  Outros: 'OTHER',
};

function setFormData(
  detail: DetailValues,
  cnabModel: string,
  productType: string,
  fileContent: string,
): FormData {
  const assignmentParams = {
    underwriterPlatformId: detail.underwriterPlatformId,
    assignorPlatformId: detail.assignorPlatformId,
    assigneePlatformId: detail.assigneePlatformId,
    endorser: detail.endorser,
    assignedAt: detail.assignedAt,
    assignmentCode: detail.assignmentCode,
    assignmentFee: detail.assignmentFee,
    layoutName: cnabModel,
    receivableType: detail.receivableType,
    allowPartialAssignment: detail.allowPartialAssignment,
    productType: mapProductType[productType],
    modifyingType: detail.modifyingType,
    modifyingFactor: detail.modifyingFactor,
    isRepurchase: detail.isRepurchase,
  };

  const formData = new FormData();
  formData.append('installmentsFile', setBlob(fileContent));
  formData.append('assignmentParams', setBlob(JSON.stringify(assignmentParams)));
  return formData;
}

export const FormCnabGenerate: React.FC = () => {
  const [cnabModel, setCnabModel] = useState<CnabValues>({
    product: '',
    model: '',
    filled: false,
  });

  const [detail, setDetail] = useState<DetailValues>({
    underwriterPlatformId: '',
    assignorPlatformId: '',
    assigneePlatformId: '',
    endorser: '',
    assignedAt: '',
    assignmentCode: '',
    assignmentFee: '',
    filled: false,
    receivableType: '',
    allowPartialAssignment: undefined,
    modifyingType: 'MANUAL',
    modifyingFactor: null,
    isRepurchase: false,
  });

  const [sheet, setSheet] = useState<SheetValues>({
    fileContent: '',
  });

  const [feedbackMessage, setFeedbackMessage] = useState({
    error: false,
    info: false,
    message: '',
  });

  const callbacks = {
    setProduct: (valor: string): void => {
      setCnabModel({ ...cnabModel, product: valor });
    },
    setModel: (valor: string): void => {
      setCnabModel({ ...cnabModel, model: valor });
    },
    setCnabModelFilled: (valor: boolean): void => {
      setCnabModel({ ...cnabModel, filled: valor });
    },
    setUnderwriterPlatformId: (valor: string): void => {
      setDetail({ ...detail, underwriterPlatformId: valor });
    },
    setAssignorPlatformId: (valor: string): void => {
      setDetail({ ...detail, assignorPlatformId: valor });
    },
    setAssigneePlatformId: (valor: string): void => {
      setDetail({ ...detail, assigneePlatformId: valor });
    },
    setEndorser: (valor: string): void => {
      setDetail({ ...detail, endorser: valor });
    },
    setAssignedAt: (valor: string): void => {
      setDetail({ ...detail, assignedAt: valor });
    },
    setAssignmentCode: (valor: string): void => {
      setDetail({ ...detail, assignmentCode: valor });
    },
    setAssignmentFee: (valor: string): void => {
      setDetail({ ...detail, assignmentFee: valor });
    },
    setAssignmentFilled: (valor: boolean): void => {
      setDetail({ ...detail, filled: valor });
    },
    setFileContent: (valor: string): void => {
      setSheet({ ...sheet, fileContent: valor });
    },
    setReceivableType: (valor: string): void => {
      setDetail({ ...detail, receivableType: valor });
    },
    setModifyingType: (valor: string): void => {
      setDetail({ ...detail, modifyingType: valor });
    },
    setModifyingFactor: (valor: string | null): void => {
      setDetail({ ...detail, modifyingFactor: valor });
    },
    setAllowPartialAssignment: (valor: boolean): void => {
      setDetail({ ...detail, allowPartialAssignment: valor });
    },
    setIsRepurchaseAssignment: (valor: boolean): void => {
      setDetail({ ...detail, isRepurchase: valor });
    },
  };

  const verifySectionFilled = (section: DetailValues): boolean => {
    const valid = Object.values(section).every(x => x !== '' && x !== undefined);
    const isModifyingFactor = section.modifyingType === 'GOODWILL';
    if (isModifyingFactor && valid) {
      return section.modifyingFactor != null;
    }
    return valid;
  };

  const { loading, execute: generateCnab } = useAsyncCallback(
    () =>
      fileEngineAPI.generateCnab(
        setFormData(detail, cnabModel.model, cnabModel.product, sheet.fileContent),
      ),
    {
      onSuccess: () => {
        setFeedbackMessage({
          ...feedbackMessage,
          error: false,
          info: true,
          message: 'Seu CNAB está sendo processado, acompanhe na lista de CNABs',
        });
      },
      onError: error => {
        let errorMessage;
        const axiosError = error as AxiosError;
        const { response } = axiosError;
        if (response !== undefined && response.data.message !== undefined) {
          errorMessage = response.data.message;
        } else {
          errorMessage =
            'Ocorreu um erro. Verifique as informações ou contate o time de tecnologia.';
        }

        setFeedbackMessage({
          ...feedbackMessage,
          error: true,
          info: false,
          message: errorMessage,
        });
      },
    },
  );

  const submitButton =
    sheet.fileContent !== '' && cnabModel.filled ? (
      <Button default onClick={generateCnab} isLoading={loading} disabled={loading}>
        Gerar CNAB
      </Button>
    ) : (
      <Button default disabled>
        Gerar CNAB
      </Button>
    );

  const handleList = () => {
    window.open('/funding-platform/cnab/cessoes');
  };

  const listButton = (
    <ListButton secondary onClick={handleList}>
      Lista de Cessões
    </ListButton>
  );

  function hide(id: string) {
    const element = document.getElementById(id);
    if (element !== null) {
      element.hidden = true;
    }
    setFeedbackMessage({
      ...feedbackMessage,
      error: false,
      info: false,
      message: '',
    });
  }
  function showElement(id: string) {
    const element = document.getElementById(id);
    if (element !== null) {
      element.hidden = false;
    }
  }
  return (
    <>
      {feedbackMessage.error && showElement('error')}
      {feedbackMessage.info && showElement('info')}

      <FeedbackMessageBox id="error" hidden onClick={() => hide('error')}>
        <FeedbackMessage variant="danger">{feedbackMessage.message}</FeedbackMessage>
      </FeedbackMessageBox>

      <FeedbackMessageBox id="info" hidden onClick={() => hide('info')}>
        <FeedbackMessage variant="info">{feedbackMessage.message}</FeedbackMessage>
      </FeedbackMessageBox>

      <FormContainer>
        <CnabModel value={cnabModel} callbacks={callbacks} />
        <Detail
          enableSection={cnabModel.filled}
          callbacks={callbacks}
          values={detail}
          fileModel={cnabModel.model}
          product={cnabModel.product}
        />
        <Sheet
          setFeedbackMessage={setFeedbackMessage}
          enableSection={verifySectionFilled(detail) && cnabModel.filled}
          callbacks={callbacks}
        />
        <FlexEnd>
          {listButton}
          {submitButton}
        </FlexEnd>
      </FormContainer>
    </>
  );
};
