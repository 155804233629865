import React, { ChangeEvent, useEffect, useState } from 'react';
import validator from 'validator';
import { Typography } from '@creditas/typography';
import { RadioInput, TextField } from '@creditas/input';
import { styled } from '@creditas/stylitas';
import InputMask, { Props } from 'react-input-mask';
import { convertToDate, getFutureBusinessDate, isWeekend } from '../../utils';
import { DetailValues } from '../../pages/FormCnabGenerate/FormCnabGenerate';
import {
  getModifyingFactorsByType,
  getModifyingTypes,
} from '../../services/modifying-installments/Modifiers';
import FundService, { FundValuesResponse, isUnderwriter, FundValues } from '../../services/Fund';
import { CheckboxField } from '../FormAttributes/Checkbox';

const FlexContainer = styled.h1`
  display: flex;
  flex-direction: row;
`;

const CheckboxWrapper = styled('div')`
  white-space: nowrap;
  max-width: 30%;
  width: auto;
  padding-top: 30px;
`;

const Label = styled.label`
  font-family: Nunito Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #556666;
  margin-bottom: 0.2rem;
`;

const StyledInput = styled.input<{ disabled?: boolean }, any>`
  font-family: Nunito Sans;
  font-size: 20px;
  font-weight: 300;
  line-height: 28px;
  width: 100%;
  background: transparent;
  margin: 0;
  padding: 12px 0 8px 0;
  border: none;
  border-bottom: 1px solid ${props => props.theme.bColorneutral05};
  box-sizing: border-box;
  -webkit-appearance: none;
  outline: none;

  &:focus {
    border: none;
    border-bottom: 2px solid ${props => props.theme.bColorsecondary05};
  }

  ::placeholder {
    color: ${props => props.theme.bColorneutral05};
  }

  ${({ disabled }) =>
    disabled &&
    `
    color: #aabbbb;
    border-color: #cccccc;
   
  `}
`;

const FlexChild = styled.div`
  flex: 1;
  margin: 5px;
`;
const FlexChildRadio = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
`;

const mapModifyingFactor: {
  [key: string]: string;
} = {
  '0.10': '10%',
};

const mapModifyingType: {
  [key: string]: string;
} = {
  GOODWILL: 'Ágio',
  MANUAL: 'Ágio manual',
  NO_GOODWILL: 'Sem ágio',
};

interface Detail {
  enableSection: boolean;
  callbacks: {
    setUnderwriterPlatformId: (value: string) => void;
    setAssignorPlatformId: (value: string) => void;
    setAssigneePlatformId: (value: string) => void;
    setEndorser: (value: string) => void;
    setAssignedAt: (value: string) => void;
    setAssignmentCode: (value: string) => void;
    setAssignmentFee: (value: string) => void;
    setAssignmentFilled: (value: boolean) => void;
    setReceivableType: (value: string) => void;
    setModifyingType: (value: string) => void;
    setModifyingFactor: (value: string | null) => void;
    setAllowPartialAssignment: (value: boolean) => void;
    setIsRepurchaseAssignment: (value: boolean) => void;
  };
  values: DetailValues;
  fileModel: string;
  product: string;
}

const fundAPI = new FundService(localStorage.getItem('greencare-access-token'));

export const Detail: React.FC<Detail> = ({
  enableSection,
  callbacks,
  values,
  fileModel,
  product,
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);
  const [isGoodwill, setIsGoodwill] = useState(false);
  const [modifyingFactors, setModifyingFactor] = useState(['']);
  const [modifyingTypes] = useState(getModifyingTypes());
  const [productState, setProductState] = useState('');

  const isOfflineAssignment = [
    'JSON_OFFLINE',
    'CNAB_550_OFFLINE',
    'JSON_BRL_ALOHA_FLOORPLAN',
  ].includes(fileModel);

  const productTypes: { [key: string]: string[] } = {
    Home: ['HOME'],
    'Auto / Moto': ['AUTO'],
    'AutoRefin (Sorocred)': ['AUTO'],
    'Previdência Privada': ['PENSION'],
    Consignado: ['PAYROLL'],
    Outros: ['PAYROLL', 'PENSION', 'AUTO', 'HOME'],
  };

  const layoutsByAdministrators: { [key: string]: string[] } = {
    CNAB_500_ONLINE: ['Oliveira Trust DTVM S.A.', 'null'],
    CNAB_550_OFFLINE: [
      'Banvox Distribuidora de Títulos e Valores Mobiliários Ltda.',
      'VERT COMPANHIA SECURITIZADORA',
      'BANCO ANDBANK (BRASIL) S.A.',
      'LIMINE TRUST DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA',
    ],
    CNAB_550_ONLINE_SMARTFIDC: [
      'Banvox Distribuidora de Títulos e Valores Mobiliários Ltda.',
      'VERT COMPANHIA SECURITIZADORA',
      'LIMINE TRUST DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA',
      'RECOVERY',
      'COMPANHIA HIPOTECARIA PIRATINI',
    ],
    CNAB_550_ONLINE_FROMTIS: [
      'Banvox Distribuidora de Títulos e Valores Mobiliários Ltda.',
      'VERT COMPANHIA SECURITIZADORA',
    ],
    JSON_ONLINE: [
      'BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.',
      'BANCO ANDBANK (BRASIL) S.A.',
    ],
    JSON_OFFLINE: [
      'BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.',
      'BANCO ANDBANK (BRASIL) S.A.',
      'VERT COMPANHIA SECURITIZADORA',
    ],
    JSON_BRL_ALOHA_FLOORPLAN: [
      'BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.',
      'BANCO ANDBANK (BRASIL) S.A.',
    ],
  };

  const [fundsList, setFundsList] = useState<Array<FundValuesResponse>>([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fundAPI.list();
      const responseData = response.data;
      setFundsList(
        responseData.sort((fund1, fund2) => fund1.nickname.localeCompare(fund2.nickname)),
      );
    };
    fetchData();
  }, []);

  useEffect(() => {
    setProductState(product);
  }, [product]);

  function underWriterOptions() {
    if (!productState) return <option>Selecione um originador</option>;
    return fundsList
      .filter(
        fund =>
          isUnderwriter(fund) &&
          filterFundByProduct(fund, productTypes[productState]) &&
          fund.active,
      )
      .map(fund => <option value={fund.platformId}>{fund.simplifiedName}</option>);
  }

  function filterFundByProduct(fund: FundValuesResponse, desiredProduct: string[]): boolean {
    return desiredProduct.every(products =>
      fund.products.includes(products as 'AUTO' | 'HOME' | 'PENSION' | 'PAYROLL'),
    );
  }

  function filterFundByLayout(fund: FundValuesResponse) {
    return layoutsByAdministrators[fileModel]?.includes(fund.administrator);
  }

  function assigneeOptions() {
    if (!productState) return <option>Selecione um cessionário</option>;
    return fundsList
      .filter(fund => {
        return (
          filterFundByProduct(fund, productTypes[productState]) &&
          filterFundByLayout(fund) &&
          fund.active &&
          underWriterOptions()
        );
      })
      .map(fund => <option value={fund.platformId}>{fund.simplifiedName}</option>)
      .concat(underWriterOptions());
  }

  function assignorOptions() {
    if (!productState) return <option>Selecione um cedente</option>;
    return fundsList
      .filter(fund => filterFundByProduct(fund, productTypes[productState]) && fund.active)
      .map(fund => <option value={fund.platformId}>{fund.simplifiedName}</option>);
  }

  const handleSelectedFileModel = () => {
    if (isOfflineAssignment) {
      callbacks.setModifyingType('MANUAL');
    }
  };

  const handleSelectedModifyingType = () => {
    const goodwill = ['GOODWILL'].includes(values.modifyingType);
    if (!goodwill) {
      callbacks.setModifyingFactor(null);
    }
    setIsGoodwill(goodwill);
    setModifyingFactor(getModifyingFactorsByType(values.modifyingType));
  };

  useEffect(() => {
    handleSelectedFileModel();
    handleSelectedModifyingType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileModel, values.modifyingType]);

  const validateDate = (inputDate: string) => {
    if (validator.isDate(inputDate, { format: 'DD/MM/YYYY' })) {
      const date = convertToDate(inputDate);
      if (date > getFutureBusinessDate(2)) {
        setErrorMessage('Data de cessão futura. Não temos ágio calculado.');
        setError(true);
      } else if (isWeekend(date)) {
        setErrorMessage('Data de cessão é no final de semana.');
        setError(true);
      } else {
        setErrorMessage('');
        setError(false);
        callbacks.setAssignedAt(inputDate);
      }
    } else {
      setErrorMessage('Digite uma data válida!');
      setError(true);
    }
    console.log('error-message', errorMessage);
  };

  const [radioOverdueChecked, setRadioOverdueChecked] = useState<boolean>(false);

  useEffect(() => {
    setRadioOverdueChecked(radioOverdueChecked);
  }, [radioOverdueChecked]);

  const handleIsRepurchase = (checked: boolean) => {
    setRadioOverdueChecked(checked);
    callbacks.setIsRepurchaseAssignment(checked);
  };

  return (
    <>
      <Typography color="black" ariant="h6">
        Detalhes
      </Typography>
      <FlexContainer>
        <FlexChild>
          <TextField
            label="Originador"
            select
            onChange={(e: ChangeEvent<HTMLSelectElement>): void => {
              callbacks.setUnderwriterPlatformId(e.target.value);
            }}
            disabled={!enableSection}
          >
            <option>Selecione um originador</option>
            {underWriterOptions()}
          </TextField>
        </FlexChild>

        <FlexChild>
          <TextField
            label="Cedente"
            select
            onChange={(e: ChangeEvent<HTMLSelectElement>): void => {
              callbacks.setAssignorPlatformId(e.target.value);
            }}
            disabled={!enableSection}
          >
            <option>Selecione um cedente</option>
            {assignorOptions()}
          </TextField>
        </FlexChild>

        <FlexChild>
          <TextField
            label="Cessionário"
            select
            onChange={(e: ChangeEvent<HTMLSelectElement>): void => {
              callbacks.setAssigneePlatformId(e.target.value);
            }}
            disabled={!enableSection}
          >
            <option>Selecione um cessionário</option>
            {assigneeOptions()}
          </TextField>
        </FlexChild>
      </FlexContainer>
      <FlexContainer>
        <FlexChild>
          <TextField
            label="Certificadora"
            select
            onChange={(e: ChangeEvent<HTMLSelectElement>): void => {
              callbacks.setEndorser(e.target.value);
            }}
            disabled={!enableSection}
          >
            <option>Selecione uma certificadora</option>
            <option value="00565447000108">Quick Soft</option>
            <option value="12499520000170">ClickSign</option>
          </TextField>
        </FlexChild>

        <FlexChild>
          <Label>Data de Cessão</Label>
          <InputMask
            placeholder="dd/mm/aaaa"
            onChange={(e: any): void => {
              validateDate(e.target.value);
            }}
            disabled={!enableSection}
            mask="99/99/9999"
          >
            {(inputProps: Props): JSX.Element => (
              <StyledInput placeholder="dd/mm/aaaa" disabled={!enableSection} />
            )}
          </InputMask>
          {error ? (
            <Typography color="danger" variant="support">
              {errorMessage}
            </Typography>
          ) : null}
        </FlexChild>

        <FlexChild>
          <TextField
            id="value"
            placeholder="Código da Cessão"
            label="Preencha o código da Cessão"
            onChange={(e: ChangeEvent<HTMLSelectElement>): void => {
              callbacks.setAssignmentCode(e.target.value);
            }}
            disabled={!enableSection}
          />
        </FlexChild>
      </FlexContainer>
      <FlexContainer>
        <FlexChild>
          <TextField
            id="assignmentFee"
            placeholder="Taxa de Cessão anual"
            label="Preencha a taxa de Cessão anual"
            onChange={(e: ChangeEvent<HTMLSelectElement>): void => {
              callbacks.setAssignmentFee(e.target.value);
            }}
            disabled={!enableSection}
          />
        </FlexChild>
        <FlexChild>
          <TextField
            label="Tipo de Recebível"
            select
            onChange={(e: ChangeEvent<HTMLSelectElement>): void => {
              callbacks.setReceivableType(e.target.value);
            }}
            disabled={!enableSection}
          >
            <option>Selecione um tipo de Recebível</option>
            <option value="CDV">CDV</option>
            <option value="CFV">CFV</option>
            <option value="CCI">CCI</option>
            <option value="CDM">CDM</option>
            <option value="CEG">CEG</option>
            <option value="CCC">CCC</option>
            <option value="CCB">CCB</option>
            <option value="CDC">CDC</option>
          </TextField>
        </FlexChild>
        {!isOfflineAssignment && (
          <FlexChild>
            <TextField
              label="Tipo de Ágio"
              select
              onChange={(e: ChangeEvent<HTMLSelectElement>): void => {
                callbacks.setModifyingType(e.target.value);
              }}
              disabled={!enableSection}
            >
              <option>Selecione um tipo de Ágio</option>
              {modifyingTypes.map(type => (
                <option value={type}>{mapModifyingType[type]}</option>
              ))}
            </TextField>
          </FlexChild>
        )}
        {isGoodwill && (
          <FlexChild>
            <TextField
              label="Valor de Ágio"
              select
              onChange={(e: ChangeEvent<HTMLSelectElement>): void => {
                callbacks.setModifyingFactor(e.target.value);
              }}
              disabled={!enableSection}
            >
              <option>Selecione o valor do ágio</option>
              {modifyingFactors.map(factor => (
                <option value={factor}>{mapModifyingFactor[factor]}</option>
              ))}
            </TextField>
          </FlexChild>
        )}
      </FlexContainer>
      <FlexContainer>
        <FlexChildRadio>
          <RadioInput
            id="allowPartialAssignment"
            label="Permitir cessão parcial"
            onChange={(): void => {
              callbacks.setAllowPartialAssignment(true);
            }}
            checked={values.allowPartialAssignment === true}
            disabled={!enableSection}
          />
          <RadioInput
            id="dontAllowPartialAssignment"
            label="Não permitir cessão parcial"
            onChange={(): void => {
              callbacks.setAllowPartialAssignment(false);
            }}
            checked={values.allowPartialAssignment === false}
            disabled={!enableSection}
          />
        </FlexChildRadio>
        <CheckboxWrapper>
          <CheckboxField
            id="isRepurchase"
            value="É recompra?"
            css={{ whiteSpace: 'nowrap', textTransform: 'uppercase' }}
            disabled={!enableSection}
            onChange={(): void => handleIsRepurchase(!radioOverdueChecked)}
            checked={radioOverdueChecked}
          />
        </CheckboxWrapper>
      </FlexContainer>
      <br />
    </>
  );
};
